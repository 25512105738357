import Recognize from '@/views/ADMIN/Recognize.vue'
import RecognizeYear from '@/components/Recognize/Year.vue'
import RecognizeBehavior from '@/components/Recognize/Behavior.vue'
import RecognizeManagement from '@/components/Recognize/Management.vue'
import RecognizeAge from '@/components/Recognize/Age.vue'
import RecognizeRanking from '@/components/Recognize/Ranking.vue'
import RecognizeContributor from '@/components/Recognize/Contributor.vue'
import RecognizeReports from '@/components/Recognize/Reports.vue'
import RecognizeDashboard from '@/components/Recognize/Dashboard.vue'

export const recognizeList = {
  path: "/reconoce",
  name: "Reconoce",
  meta: { requiresAuth: true},
  component: Recognize,
  redirect: { name: "recognize-Dashboard" },
  children: [ {
      path: "year",
      name: "recognize-year",
      component: RecognizeYear
    }, {
      path: "behavior",
      name: "recognize-behavior",
      component: RecognizeBehavior
    }, {
      path: "management",
      name: "recognize-management",
      component: RecognizeManagement
    }, {
      path: "age",
      name: "recognize-age",
      component: RecognizeAge
    }, {
      path: "ranking",
      name: "recognize-ranking",
      component: RecognizeRanking
    }, {
      path: "contributor",
      name: "recognize-contributor",
      component: RecognizeContributor
    }, {
      path: "reports",
      name: "recognize-reports",
      component: RecognizeReports
    }, {
      path: "Dashboard",
      name: "recognize-Dashboard",
      component: RecognizeDashboard
    },
  ],
};