<template>

  <section id="lista-videos-muro-digital">

    <div class="row">
      <div v-for="(image, i) in images" class="col-6 col-xxl-4" :key="i">  
        <div class="card border-0 card-shadow card-item-add mb-3">
          <div class="card-body d-flex flex-column flex-xl-row">
            <div class="flex-shrink-0 text-center text-xl-start">
              <img :src="image.url_archivo" class="img-fluid img-obj card-item-add-img rounded"> 
            </div>
            <div class="card-item-add-content d-flex flex-column flex-grow-1 mt-3 mt-xl-0 ms-0 ms-xl-3">
              <h5 class="card-item-add-content-title flex-grow-1 font-main color-gray">
                {{ image.titulo_video | capitalize }}
              </h5>
              <div class="d-flex justify-content-end align-items-center text-secondary"> 
                <small class="bg-primary text-white rounded px-2">{{ image.fecha_creacion | fechaVideo }}</small>
              </div>
            </div>
          </div>
          <div class="card-item-add-config">
            <a 
              href="javascript:" 
              @click="viewDetail(image)"
              class="card-item-add-config-btn"
              data-bs-toggle="modal" 
              data-bs-target="#modal-detail-video"
              content="Ver imagen" 
              v-tippy="{ arrow: true }"
            >
              <font-awesome-icon icon="eye"/>
            </a>
            <a 
              href="javascript:" 
              @click="addVideo(image)"
              class="card-item-add-config-btn"
              content="Seleccionar imagen" 
              v-tippy="{ arrow: true }"
            >
              <font-awesome-icon icon="check"/>
            </a>
            <a 
              href="javascript:" 
              v-if="isVideoFromRepo(image)"
              @click="openQuestionDelete(image)"
              class="card-item-add-config-btn"
              content="Eliminar imagen"
              v-tippy="{ arrow: true }" 
            >
              <font-awesome-icon icon="trash-alt"/>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <paginate
          v-model="pagination.actual_page"
          :page-count="pagination.page_count"
          :page-range="pagination.page_range"
          :click-handler="pagination.click_handler"
          :prev-text="pagination.prev_text"
          :next-text="pagination.next_text"
          :container-class="pagination.container_class"
          :page-class="pagination.page_class"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <ListaReproduccion
          :selected_items="selectedMediaList"
          thumbnailField="url_imagen"
          @deleteItemFromList="deleteVideoFromList"
        />
      </div>
    </div>

    <!-- Modal -->
    <div 
      class="modal modal-custom fade" 
      id="modal-detail-video" 
      tabindex="-1" 
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">
              <font-awesome-icon icon="video" class="color-main pe-2"/>
              <span> {{ videoDetail.titulo_video }}</span>
            </h1>
            <button 
              type="button" 
              class="modal-custom-close"
              data-bs-dismiss="modal" 
              aria-label="Close">
              <font-awesome-icon icon="xmark"/>
            </button>
          </div>
          <div class="modal-body">
            <img
              :src="videoDetail.url_archivo"
              controls 
              class="border w-100"
              alt="Video"
            />
            <div class="alert alert-light border text-center mt-3 mb-0">
              {{ videoDetail.fecha_creacion | fechaVideo }}
            </div>
          </div>
          <div class="modal-footer">
            <button 
              type="button" 
              ref="closeModal"
              class="btn btn-custom-color-white rounded-pill mw-100" 
              data-bs-dismiss="modal">
              <i class="fa-solid fa-angle-left pe-1"></i>
              Volver
            </button>
            <button 
              type="button" 
              class="btn btn-custom-color-blue rounded-pill mw-100"
              @click="addVideo(videoDetail)">
              Añadir
            </button>
          </div>
        </div>
      </div>
    </div>

    <Question
      v-if="open_question_modal"
      :msg="question_modal_msg"
      :hideCancel="false"
      @cancel="cancelQuestion"
      @accept="acceptQuestion"
    />
    <Spinner v-if="show_spinner" />
  </section>

</template>
 
<script>
import { mapState, mapActions } from "vuex";
import Question from "../Modales/Question.vue";
import ListaReproduccion from "./ListaReproduccion.vue";
import Spinner from "../Spinner.vue";
import moment from 'moment';

export default {
  components: { Question, Spinner, ListaReproduccion },
  props: {
    images: {
      type: Array,
    },
    pagination: {
      type: Object
    }
  },

  data() {
    return {
      selected_videos: [],
      view_detail: false,
      videoDetail: {},
      show_spinner: false,
      open_question_modal: false,
      video_to_delete: null,
      question_modal_msg: "¿Estás seguro(a) de eliminar este video?"
    };
  },

  watch: {
    selectedMediaList() {
      this.$emit("playlistChange", this.selectedMediaList);
    },
  },

  computed: {
    ...mapState("tvCorporativaModule", ["channelLayout","selectedMediaList"]),
  },

  filters: {
    fechaVideo(fecha) {
      if (!fecha) return '';
      return moment(fecha).format('DD/MM/YYYY');
    },
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString().toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  
  mounted() {
    this.setPlaylistFromState();
  },
  
  methods: {
    ...mapActions("tvCorporativaModule", [
      "getChannelStateFromLS", 
      "deleteFileRepositorio",
      "setSelectedMediaList",
      "removeSelectedMediaList"
    ]),

    isVideoFromRepo(video) {
      if (video.id_video) {
        return false;
      } else {
        return true;
      }
    },
    
    async setPlaylistFromState() {
      if (this.channelLayout.videos.length === 0) {
        await this.getChannelStateFromLS();
      }
      if (this.channelLayout.videos.length !== 0 && this.selectedMediaList.length === 0) {
        this.setSelectedMediaList(this.channelLayout.videos.slice());
      }
    },

    viewDetail(video) {
      this.view_detail = true;
      this.videoDetail = video;
    },
    closeDetail() {
      this.view_detail = false;
    },
    addVideo(data_) {
      if (this.view_detail == true) {
        this.view_detail = false;
      }
      this.setSelectedMediaList([data_]);
      this.$refs.closeModal.click();
    },

    async deleteVideo(video) {
      this.show_spinner = true;
      const data = {
        type:'3',
        id_archivo: +video.id_archivo
      };
      const res = await this.deleteFileRepositorio(data);
      this.show_spinner = false;
      this.open_question_modal = false;
      if (!res) {
        this.toastVideoDeleteFalla();
      } else {
        if (res.nombre_archivo.includes("in use")) {
          this.toastVideoDeleteEnUso();
        } else {
          this.toastVideoDeleteExitoso();
          this.$emit("imageDeleted", res);
        }
      }
    },

    openQuestionDelete(video) {
      this.video_to_delete = video;
      this.open_question_modal = true;
    },


    async acceptQuestion() {
      await this.deleteVideo(this.video_to_delete);
    },

    cancelQuestion() {
      this.open_question_modal = false;
    },

    deleteVideoFromList(index) {
      this.removeSelectedMediaList(index);
    },

    toastVideoDeleteEnUso() {
			this.$toast.open({
				message: "Esta imagen no se puede eliminar ya que está en una lista de reproducción de algún usuario de tu misma empresa.",
				type: "warning",
				duration: 6000,
				position: "top-right",
			});
    },
    toastVideoDeleteFalla() {
			this.$toast.open({
				message: "Hubo errores al momento de eliminar imagen. Por favor inténtalo nuevamente",
				type: "error",
				duration: 6000,
				position: "top-right",
			});
    },
    toastVideoDeleteExitoso() {
			this.$toast.open({
				message: "La imagen ha sido eliminada exitosamente.",
				type: "success",
				duration: 6000,
				position: "top-right",
			});
    }
  },
};
</script>