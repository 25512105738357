import gql from "graphql-tag";

export const GET_ESTADISTICAS_USABILIDAD = gql`
  query DashboardEmpresaYear($id_empresa: String!, $fecha: String!) {
    DashboardEmpresaYear(id_empresa: $id_empresa, fecha: $fecha) {
      total_activos #
      total_enlinea
      total_usuarios #
      usabilidad #
      usuarios_plan {
        id_plan
        cantidad_usuarios
        cantidad_pantallas
      }
    }
  }
`;

export const GET_VIDEOS = gql`
  query getVideos($id_empresa: String!) {
    videos(id_empresa: $id_empresa) {
      id_video
      titulo_video
      descripcion_video
      nombre_carpeta
      url_video
      url_imagen
      id_empresa_fk
      categoria {
        id_categoria
        nombre_categoria
      }
    }
  }
`;
export const GET_USERS = gql`
  query usuarios($id_empresa: String!, $id_usuario: String!) {
    usuarios(id_empresa: $id_empresa, id_usuario: $id_usuario) {
      id_usuario
      rut_usuario
      dv_usuario
      primer_nombre
      segundo_nombre
      apellido_paterno
      apellido_materno
      correo
      telefono_movil
      anexo
      telefono_fijo
      genero
      imagen_perfil
      fecha_nacimiento
      fecha_ingreso
      id_sucursal_fk
      id_privilegio_fk
    }
  }
`;
// USER PARA SELECTORES
export const GET_USERS_SELECT = gql`
  query usuarios($id_empresa: String!, $id_usuario: String!) {
    usuarios(id_empresa: $id_empresa, id_usuario: $id_usuario) {
      id_usuario
      primer_nombre
      apellido_paterno
      imagen_perfil
      cargo {
        id_cargo
        nombre_cargo
      }
      sucursal {
        id_sucursal
        nombre_sucursal
      }
    }
  }
`;
export const CHECK_EMAIL_RUT = gql`
  query CheckEmailRut(
    $id_empresa: String!
    $objeto: String!
    $tipo_objeto: Int!
  ) {
    CheckEmailRut(
      id_empresa: $id_empresa
      objeto: $objeto
      tipo_objeto: $tipo_objeto
    ) {
      id_usuario
      check
      correo
      rut_usuario
      dv_usuario
    }
  }
`;
//////////usuarios List/////////
export const GET_USER = gql`
  query usuario($id_usuario: String!, $id_empresa: String!) {
    usuario(id_usuario: $id_usuario, id_empresa: $id_empresa) {
      id_usuario
      rut_usuario
      dv_usuario
      primer_nombre
      segundo_nombre
      apellido_paterno
      apellido_materno
      correo
      telefono_movil
      anexo
      telefono_fijo
      genero
      imagen_perfil
      fecha_nacimiento
      fecha_ingreso
      empresa {
        id_empresa
      }
      nacion {
        id_nacion
        nombre_nacion
      }
      sociedad {
        id_sociedad
        nombre_sociedad
      }
      sindicato {
        id_sindicato
        nombre_sindicato
      }
      grupo {
        id_grupo
        nombre_grupo
      }
      cargo {
        id_cargo
        nombre_cargo
      }
      gerencia {
        id_gerencia
        nombre_gerencia
      }
      sucursal {
        id_sucursal
        nombre_sucursal
      }
      turno {
        id_turno
        nombre_turno
      }
      team {
        id_team
        nombre_team
      }
      privilegio {
        id_tipo
        nombre_tipo
      }
    }
  }
`;
export const GET_USER_TOTAL_PAGINACION = gql`
  query listUsersPaginacion($id_empresa: String!, $id_usuario: [String]!) {
    listUsersPaginacion(id_empresa: $id_empresa, id_usuario: $id_usuario) {
      id_usuario
      rut_usuario
      dv_usuario
      primer_nombre
      segundo_nombre
      apellido_paterno
      apellido_materno
      correo
      telefono_movil
      anexo
      telefono_fijo
      genero
      imagen_perfil
      fecha_nacimiento
      fecha_ingreso
      empresa {
        id_empresa
      }
      nacion {
        id_nacion
        nombre_nacion
      }
      sociedad {
        id_sociedad
        nombre_sociedad
      }
      sindicato {
        id_sindicato
        nombre_sindicato
      }
      grupo {
        id_grupo
        nombre_grupo
      }
      cargo {
        id_cargo
        nombre_cargo
      }
      gerencia {
        id_gerencia
        nombre_gerencia
      }
      sucursal {
        id_sucursal
        nombre_sucursal
      }
      turno {
        id_turno
        nombre_turno
      }
      privilegio {
        id_tipo
        nombre_tipo
      }
    }
  }
`;
export const GET_USER_PAGINATION = gql`
  query TotalUsuariosPaginacion(
    $id_empresa: String!
    $id_usuario: String!
    $nombre_usuario: String!
    $limite: String!
  ) {
    TotalUsuariosPaginacion(
      id_empresa: $id_empresa
      id_usuario: $id_usuario
      nombre_usuario: $nombre_usuario
      limite: $limite
    ) {
      pagina
      usuarios
    }
  }
`;
//////////usuarios List/////////
/* Galería */
export const GET_GALERIA = gql`
  query galeria(
    $id_empresa: String!
    $id_galeria: String!
    $id_usuario: String!
    $plataforma: String!
  ) {
    galeria(
      id_empresa: $id_empresa
      id_galeria: $id_galeria
      id_usuario: $id_usuario
      plataforma: $plataforma
    ) {
      id_galeria
      titulo_galeria
      fecha_galeria
      id_estado_fk
      nombre_carpeta
      total_likes_admin
      nombre_carpeta
      imagenes {
        id_imagen
        url_imagen_thumb
        url_imagen_original
        likes {
          id_like
          usuario {
            id_usuario
          }
        }
      }
    }
  }
`;
export const GET_GALERIAS_BY_DATE = gql`
  query galeriasMesYear(
    $id_empresa: String!
    $year: String!
    $month: String!
    $id_usuario: String!
  ) {
    galeriasMesYear(
      id_empresa: $id_empresa
      year: $year
      month: $month
      id_usuario: $id_usuario
    ) {
      id_galeria
      titulo_galeria
      fecha_galeria
      imagenes {
        id_imagen
        url_imagen_thumb
        url_imagen_original
      }
    }
  }
`;
export const GET_YEARS_GALERIA = gql`
  query yearGaleria($id_empresa: String!, $id_usuario: String!) {
    yearGaleria(id_empresa: $id_empresa, id_usuario: $id_usuario) {
      year
    }
  }
`;
export const GET_MONTHS_GALERIA = gql`
  query monthGaleria(
    $id_empresa: String!
    $year: String!
    $id_usuario: String!
  ) {
    monthGaleria(
      id_empresa: $id_empresa
      year: $year
      id_usuario: $id_usuario
    ) {
      month
    }
  }
`;

export const PAGINAS_GALERIAS = gql`
  query PaginasGaleriaAdmin(
    $id_empresa: String!
    $nombre: String!
    $fecha_final: String!
    $fecha_inicio: String!
    $limite: String!
  ) {
    PaginasGaleriaAdmin(
      id_empresa: $id_empresa
      nombre: $nombre
      fecha_final: $fecha_final
      fecha_inicio: $fecha_inicio
      limite: $limite
    ) {
      pagina
      galerias
    }
  }
`;

export const LIST_PAGINADA_GALERIA = gql`
  query galeriasIdAdmin(
    $id_empresa: String!
    $id_usuario: String!
    $id_galeria: [String]!
  ) {
    galeriasIdAdmin(
      id_empresa: $id_empresa
      id_usuario: $id_usuario
      id_galeria: $id_galeria
    ) {
      id_galeria
      titulo_galeria
      fecha_galeria
      id_estado_fk
      alerta_push
      nombre_carpeta
      total_likes_admin
      nombre_carpeta
      imagenes {
        id_imagen
        url_imagen_thumb
        url_imagen_original
        likes {
          id_like
          usuario {
            id_usuario
          }
        }
      }
    }
  }
`; /* Galería */

export const GET_PRIVILEGIOS = gql`
  query privilegios {
    privilegios {
      id_tipo
      nombre_tipo
    }
  }
`;
export const GET_CARGOS = gql`
  query cargos($id_empresa: String!) {
    cargos(id_empresa: $id_empresa) {
      id_cargo
      nombre_cargo
    }
  }
`;
export const GET_GERENCIAS = gql`
  query gerencias($id_empresa: String!) {
    gerencias(id_empresa: $id_empresa) {
      id_gerencia
      nombre_gerencia
    }
  }
`;
export const GET_SUCURSALES = gql`
  query sucursales($id_empresa: String!) {
    sucursales(id_empresa: $id_empresa) {
      id_sucursal
      nombre_sucursal
    }
  }
`;
export const GET_WORK_SHIFTS = gql`
  query turnos($id_empresa: String!) {
    turnos(id_empresa: $id_empresa) {
      id_turno
      nombre_turno
    }
  }
`;
export const GET_EMPRESA = gql`
  query Empresa {
    empresas {
      id_empresa
      nombre_empresa
    }
  }
`;
export const GET_SINDICATO = gql`
  query sindicatos($id_empresa: String!) {
    sindicatos(id_empresa: $id_empresa) {
      id_sindicato
      nombre_sindicato
    }
  }
`;

export const GET_RECONOCIMIENTO_PLANTA = gql`
  query reconocimientosPlantaYearMonth(
    $id_empresa: String!
    $fecha_inicio: String!
    $fecha_final: String!
    $id_planta: String!
  ) {
    reconocimientosPlantaYearMonth(
      id_empresa: $id_empresa
      fecha_inicio: $fecha_inicio
      fecha_final: $fecha_final
      id_planta: $id_planta
    ) {
      id_valor
      nombre_valor
      cantidad
    }
  }
`;
export const GET_RECONOCIMIENTO_BY_MONTH = gql`
  query getRecono($id_empresa: String!, $year: String!) {
    cantidadReconocimientosByMonth(id_empresa: $id_empresa, year: $year) {
      month
      cantidad
    }
  }
`;
export const GET_GERENCIAS_DATA = gql`
  query reconocimientosGerenciasYearMonth(
    $id_empresa: String!
    $fecha_inicio: String!
    $fecha_final: String!
    $id_gerencia: String!
  ) {
    reconocimientosGerenciasYearMonth(
      id_empresa: $id_empresa
      fecha_inicio: $fecha_inicio
      fecha_final: $fecha_final
      id_gerencia: $id_gerencia
    ) {
      id_valor
      nombre_valor
      cantidad
    }
  }
`;

// GET_CATEGORIAS solo para listas con foco en filtrar.
export const GET_CATEGORIAS = gql`
  query categorias($id_empresa: String!) {
    categorias(id_empresa: $id_empresa) {
      id_categoria
      nombre_categoria
      especial
    }
  }
`;
// QUERY PARA OBTENER LAS CATEGORÍAS SIN EL TODAS(ES PARA LOS FORMULARIOS)
export const GET_CATEGORIAS_LIST = gql`
  query categoriasAdministrador($id_empresa: String!) {
    categoriasAdministrador(id_empresa: $id_empresa) {
      id_categoria
      nombre_categoria
      especial
    }
  }
`;
/* NOTICIAS */
export const GET_CONTACTOS = gql`
  query contactos(
    $id_empresa: String!
    $nombre_buscar: String!
    $id_gerencia: String!
  ) {
    contactos(
      id_empresa: $id_empresa
      nombre_buscar: $nombre_buscar
      id_gerencia: $id_gerencia
    ) {
      id_usuario
      primer_nombre
      segundo_nombre
      apellido_paterno
      apellido_materno
      rut_usuario
      dv_usuario
      correo
      imagen_perfil
      cargo {
        nombre_cargo
      }
      sucursal {
        nombre_sucursal
      }
    }
  }
`;
export const GET_NOTICIAS = gql`
  query getNew(
    $id_usuario: String!
    $id_empresa: String!
    $id_categoria: String!
    $especial_categoria: String!
  ) {
    noticias(
      id_usuario: $id_usuario
      id_empresa: $id_empresa
      id_categoria: $id_categoria
      especial_categoria: $especial_categoria
    ) {
      id_noticia
      titulo_noticia
      url_imagen_principal
      fecha_noticia
      alerta_push
      tipo_noticia {
        id_tipo
        nombre_tipo
      }
      categoria {
        id_categoria
        nombre_categoria
        especial
      }
      contenido {
        id_contenido
        id_noticia_fk
        texto
        url_imagen
        url_externo_app
        url_externo_intranet
        url_video
        url_documento
        #nombre_documento
        usuario {
          id_usuario
          primer_nombre
          segundo_nombre
          apellido_paterno
          apellido_materno
          rut_usuario
          dv_usuario
        }
        tipo_contenido {
          id_tipo_contenido
          nombre_tipo_contenido
        }
        likes {
          id_like
        }
        vistos {
          id_usuario_fk
        }
        confirm_like {
          id_usuario_fk
        }
      }
    }
  }
`;
export const PAGINADOR_NOTICIAS_ADMIN_NE = gql`
  query PaginadorNoticiasAdminNE(
    $nombre_noticia: String!
    $id_empresa: String!
    $id_categoria: String!
    $fecha_inicio: String!
    $fecha_fin: String!
    $limite: String!
  ) {
    paginadorNoticiasAdminNE(
      nombre_noticia: $nombre_noticia
      id_empresa: $id_empresa
      id_categoria: $id_categoria
      fecha_inicio: $fecha_inicio
      fecha_fin: $fecha_fin
      limite: $limite
    ) {
      pagina
      noticias
    }
  }
`;

export const NOTICIAS_PAGINADAS_ADMIN_NE = gql`
  query NoticiasPaginadasAdminNE(
    $id_usuario: String!
    $id_empresa: String!
    $id_noticia: [String]
  ) {
    noticiasPaginadasAdminNE(
      id_usuario: $id_usuario
      id_empresa: $id_empresa
      id_noticia: $id_noticia
    ) {
      id_noticia
      id_estado_fk
      alerta_push
      titulo_noticia
      url_imagen_principal
      fecha_noticia
      fecha_creacion
      tipo_noticia {
        id_tipo
        nombre_tipo
      }
      categoria {
        id_categoria
        nombre_categoria
        especial
      }
      contenido {
        id_contenido
        id_noticia_fk
        texto
        url_imagen
        url_externo_app
        url_externo_intranet
        url_video
        url_documento
        usuario {
          id_usuario
          primer_nombre
          segundo_nombre
          apellido_paterno
          apellido_materno
          rut_usuario
          dv_usuario
          imagen_perfil
        }
        tipo_contenido {
          id_tipo_contenido
          nombre_tipo_contenido
        }
      }
    }
  }
`;

export const GET_NOTICIA = gql`
  query noticia(
    $id_usuario: String!
    $id_empresa: String!
    $id_noticia: String!
  ) {
    noticia(
      id_usuario: $id_usuario
      id_empresa: $id_empresa
      id_noticia: $id_noticia
    ) {
      id_noticia
      titulo_noticia
      url_imagen_principal
      fecha_noticia
      alerta_push
      tipo_noticia {
        id_tipo
        nombre_tipo
      }
      categoria {
        id_categoria
        nombre_categoria
        especial
      }
      contenido {
        id_contenido
        id_noticia_fk
        texto
        url_imagen
        url_externo_app
        url_externo_intranet
        url_video
        url_documento
        #nombre_documento
        usuario {
          id_usuario
          primer_nombre
          segundo_nombre
          apellido_paterno
          apellido_materno
          rut_usuario
          dv_usuario
          imagen_perfil
        }
        tipo_contenido {
          id_tipo_contenido
          nombre_tipo_contenido
        }
      }
    }
  }
`;
export const GET_NOTICIAS_INTRANET = gql`
  query noticiasIntranet(
    $id_usuario: String!
    $id_empresa: String!
    $id_categoria: String!
    $limite: String!
  ) {
    paginadorNoticiasApp(
      id_usuario: $id_usuario
      id_empresa: $id_empresa
      id_categoria: $id_categoria
      limite: $limite
    ) {
      pagina
      noticias
    }
  }
`;
export const GET_NOTICIAS_INTRANET_PAGINACION = gql`
  query noticiasIntranetPaginacion(
    $id_usuario: String!
    $id_empresa: String!
    $id_noticia: [String]
  ) {
    noticiasPaginadas(
      id_usuario: $id_usuario
      id_empresa: $id_empresa
      id_noticia: $id_noticia
    ) {
      id_noticia
      titulo_noticia
      url_imagen_principal
      fecha_noticia
      tipo_noticia {
        id_tipo
        nombre_tipo
      }
      categoria {
        id_categoria
        nombre_categoria
        especial
      }
      contenido {
        id_contenido
        id_noticia_fk
        texto
        url_imagen
        url_externo_app
        url_externo_intranet
        url_video
        url_documento
        #nombre_documento
        usuario {
          id_usuario
          primer_nombre
          segundo_nombre
          apellido_paterno
          apellido_materno
          rut_usuario
          dv_usuario
        }
        tipo_contenido {
          id_tipo_contenido
          nombre_tipo_contenido
        }
      }
      comentarios {
        id_comentario
        texto
        fecha_creacion
        usuario {
          primer_nombre
          apellido_paterno
          imagen_perfil
        }
        likes {
          id_like
        }
      }
      likes {
        id_like
        id_noticia_fk
      }
      vistos {
        id_usuario_fk
      }
    }
  }
`;
export const GET_NOTICIAS_ADMIN = gql`
  query noticiasPaginadasAdmin(
    $nombre_noticia: String!
    $id_empresa: String!
    $id_categoria: String!
    $limit: String!
    $last_id: String!
    $last_fecha: String!
  ) {
    noticiasPaginadasAdmin(
      nombre_noticia: $nombre_noticia
      id_empresa: $id_empresa
      id_categoria: $id_categoria
      limit: $limit
      last_id: $last_id
      last_fecha: $last_fecha
    ) {
      id_noticia
      titulo_noticia
      url_imagen_principal
      fecha_noticia
      fecha_creacion
      tipo_noticia {
        id_tipo
        nombre_tipo
      }
      categoria {
        id_categoria
        nombre_categoria
        especial
      }
      contenido {
        id_contenido
        id_noticia_fk
        texto
        url_imagen
        url_externo_app
        url_externo_intranet
        url_video
        url_documento
        #nombre_documento
        usuario {
          id_usuario
          primer_nombre
          segundo_nombre
          apellido_paterno
          apellido_materno
          rut_usuario
          dv_usuario
          imagen_perfil
        }
        tipo_contenido {
          id_tipo_contenido
          nombre_tipo_contenido
        }
      }
    }
  }
`;
export const GET_NOTICIAS_ADMIN_MINI = gql`
  query noticiasAdmin(
    $nombre_noticia: String!
    $id_empresa: String!
    $id_categoria: String!
    $especial_categoria: String!
  ) {
    noticiasAdmin(
      nombre_noticia: $nombre_noticia
      id_empresa: $id_empresa
      id_categoria: $id_categoria
      especial_categoria: $especial_categoria
    ) {
      id_noticia
      fecha_noticia
    }
  }
`;
export const GET_NOTICIAS_ADMIN_TOTAL = gql`
  query TotalNoticiasAdmin(
    $nombre_noticia: String!
    $id_empresa: String!
    $id_categoria: String!
  ) {
    TotalNoticiasAdmin(
      nombre_noticia: $nombre_noticia
      id_empresa: $id_empresa
      id_categoria: $id_categoria
    ) {
      total
    }
  }
`;
export const TOTAL_NOTICIAS_ADMIN_FECHA = gql`
  query TotalNoticiasAdminFecha(
    $nombre_noticia: String!
    $id_empresa: String!
    $id_categoria: String!
    $fecha_inicio: String!
    $fecha_fin: String!
  ) {
    TotalNoticiasAdminFecha(
      nombre_noticia: $nombre_noticia
      id_empresa: $id_empresa
      id_categoria: $id_categoria
      fecha_inicio: $fecha_inicio
      fecha_fin: $fecha_fin
    ) {
      total
    }
  }
`;
export const NOTICIAS_PAGINADAS_ADMIN_FECHA = gql`
  query NoticiasPaginadasAdminFecha(
    $nombre_noticia: String!
    $id_empresa: String!
    $id_categoria: String!
    $limit: String!
    $last_id: String!
    $last_fecha: String!
    $fecha_inicio: String!
    $fecha_fin: String!
  ) {
    noticiasPaginadasAdminFecha(
      nombre_noticia: $nombre_noticia
      id_empresa: $id_empresa
      id_categoria: $id_categoria
      limit: $limit
      last_id: $last_id
      last_fecha: $last_fecha
      fecha_inicio: $fecha_inicio
      fecha_fin: $fecha_fin
    ) {
      id_noticia
      titulo_noticia
      url_imagen_principal
      fecha_noticia
      fecha_creacion
      alerta_push
      tipo_noticia {
        id_tipo
        nombre_tipo
      }
      categoria {
        id_categoria
        nombre_categoria
        especial
      }
      contenido {
        id_contenido
        id_noticia_fk
        texto
        url_imagen
        url_externo_app
        url_externo_intranet
        url_video
        url_documento
        usuario {
          primer_nombre
          apellido_paterno
          apellido_materno
          id_usuario
        }
        tipo_contenido {
          id_tipo_contenido
          nombre_tipo_contenido
        }
      }
    }
  }
`;
export const BIRTHDAYS_TODAY = gql`
  query birthdaysToday($id_empresa: String!) {
    birthdaysToday(id_empresa: $id_empresa) {
      id_usuario
      primer_nombre
      apellido_paterno
      imagen_perfil
      cargo {
        nombre_cargo
      }
    }
  }
`;
export const BIRTHDAYS_MONTH = gql`
  query birthdaysMonth($id_empresa: String!) {
    birthdaysMonth(id_empresa: $id_empresa) {
      month
    }
  }
`;
export const BIRTHDAYS_DAYS = gql`
  query birthdaysDays($id_empresa: String!, $month: String!) {
    birthdaysDays(id_empresa: $id_empresa, month: $month) {
      day
    }
  }
`;
export const BIRTHDAYS_DATES = gql`
  query birthdaysDates($id_empresa: String!, $month: String!, $day: String!) {
    birthdaysDates(id_empresa: $id_empresa, month: $month, day: $day) {
      id_usuario
      primer_nombre
      apellido_paterno
      imagen_perfil
      cargo {
        nombre_cargo
      }
    }
  }
`;
export const GET_GROUPS = gql`
  query listGruposAcciones($id_tipo_privilegio: String!, $id_empresa: String!) {
    listGruposAcciones(
      id_tipo_privilegio: $id_tipo_privilegio
      id_empresa: $id_empresa
    ) {
      id_grupo
      nombre_grupo
    }
  }
`;
export const GET_SOCIETIES = gql`
  query sociedades($id_empresa: String!) {
    sociedades(id_empresa: $id_empresa) {
      id_sociedad
      nombre_sociedad
    }
  }
`;
export const GET_NATIONS = gql`
  query naciones($id_empresa: String!) {
    naciones(id_empresa: $id_empresa) {
      id_nacion
      nombre_nacion
    }
  }
`;
export const GET_SEGMENTACION = gql`
  query tipoSegmentacion {
    tipoSegmentacion {
      id_elementos_segmentacion
      nombre_segmentacion
    }
  }
`;
export const GET_SEGMENTACION_ELEMENTOS = gql`
  query FiltrosPush($idEmpresa: String!) {
    FiltrosPush(id_empresa: $idEmpresa) {
      gerencias {
        id_gerencia
        nombre_gerencia
        bussiness_unit
        unit_number
      }
      sucursales {
        id_sucursal
        nombre_sucursal
      }
      sindicatos {
        id_sindicato
        nombre_sindicato
      }
      generos {
        id_genero
        nombre_genero
      }
    }
  }
`;
export const GET_INDICADORES_RECONOCIMIENTOS_ANUALES = gql`
  query IndicadoresReconocimientosAnuales(
    $id_empresa: String!
    $year: String!
  ) {
    IndicadoresReconocimientosAnuales(id_empresa: $id_empresa, year: $year) {
      month
      cantidad
    }
  }
`;
export const GET_INDICADORES_RECONOCEDORES_ANUALES = gql`
  query IndicadoresReconocedoresAnuales($id_empresa: String!, $year: String!) {
    IndicadoresReconocedoresAnuales(id_empresa: $id_empresa, year: $year) {
      month
      cantidad
    }
  }
`;

export const GET_RECONOCIMIENTOS_USUARIO = gql`
  query reconocidosYearMonthReconocimientosName(
    $nombre_usuario: String!
    $id_empresa: String!
    $fecha_inicio: String!
    $fecha_final: String!
    $pagina: Int!
  ) {
    reconocidosYearMonthReconocimientosName(
      nombre_usuario: $nombre_usuario
      id_empresa: $id_empresa
      fecha_inicio: $fecha_inicio
      fecha_final: $fecha_final
      pagina: $pagina
    ) {
      previous_page
      next_page
      count
      total_pages
      data {
        id_usuario
        primer_nombre
        segundo_nombre
        apellido_paterno
        apellido_materno
        rut_usuario
        dv_usuario
        imagen_perfil
        genero
        cargo: empresa_cargos {
          id_cargo
          nombre_cargo
        }
        sucursal {
          id_sucursal
          nombre_sucursal
        }
        gerencia {
          id_gerencia
          nombre_gerencia
          bussiness_unit
          unit_number
        }
        reconocimientos_recibidos: reconocimientos {
          id_reconocimiento
          url_imagen
          comentario_reconocimiento
          fecha_creacion
          visto
          nombre_grupo
          reconocedor {
            primer_nombre
            apellido_paterno
          }
          valor_comportamiento {
            valor {
              id_valor
              nombre_valor
              color
              id_estado_fk
            }
          }
        }
        reconocimientos_enviados {
          id_reconocimiento
          url_imagen
          comentario_reconocimiento
          fecha_creacion
          visto
          nombre_grupo
          reconocidos {
            id_usuario
            primer_nombre
            segundo_nombre
            apellido_paterno
            apellido_materno
            rut_usuario
            dv_usuario
          }
          valor_comportamiento {
            valor {
              id_valor
              nombre_valor
              color
              id_estado_fk
            }
          }
        }
        reconocimientos_valor {
          id_valor
          nombre_valor
          cantidad
          color
        }
      }
    }
  }
`;

export const GET_RECONOCIMIENTOS_REPORTE = gql`
  query reconocimientosReportes(
    $id_empresa: String!
    $nombre_usuario: String!
    $fecha_inicio: String!
    $fecha_fin: String!
    $pagina: Int!
  ) {
    reconocimientosReportes(
      id_empresa: $id_empresa
      nombre_usuario: $nombre_usuario
      fecha_inicio: $fecha_inicio
      fecha_final: $fecha_fin
      pagina: $pagina
    ) {
      previous_page
      next_page
      count
      total_pages

      data {
        id_reconocimiento
        url_imagen
        comentario_reconocimiento
        fecha_creacion
        visto
        reconocidos {
          primer_nombre
          apellido_paterno
          apellido_materno
          rut_usuario
          dv_usuario
          imagen_perfil
          cargo {
            nombre_cargo
          }
          gerencia {
            nombre_gerencia
          }
        }
        reconocedor {
          primer_nombre
          apellido_paterno
          apellido_materno
          rut_usuario
          dv_usuario
          imagen_perfil
          cargo {
            nombre_cargo
          }
          gerencia {
            nombre_gerencia
          }
        }

        likes {
          id_like
        }
        valor_comportamiento {
          id_valor_comportamiento
          valor {
            id_valor
            nombre_valor
            id_estado_fk
          }
        }
        relacion {
          id_relacion
          nombre_relacion
          id_estado_fk
        }
      }
    }
  }
`;
export const GET_MODULES_ADMIN = gql`
  query modulosAdmin($id_empresa: String!, $id_privilegio: String!) {
    modulosAdmin(id_empresa: $id_empresa, id_privilegio: $id_privilegio) {
      id_detalle
      nombre_modulo
      imagen_url
      url_menu_activo
      url_menu_inactivo
      moduloGeneral {
        id_modulo
        nombre_modulo
        descripcion_modulo
        is_web
        ruta_admin
      }
    }
  }
`;
/** Nueva query para obtener los módulos */
export const GET_MODULES_ADMIN_PRIVILEGIOS = gql`
  query modulosAdminUpdated(
    $id_empresa: String!
    $id_privilegio: String!
    $id_grupo: String!
  ) {
    modulosAdminUpdated(
      id_empresa: $id_empresa
      id_privilegio: $id_privilegio
      id_grupo: $id_grupo
    ) {
      id_detalle
      nombre_modulo
      imagen_url
      url_menu_activo
      url_menu_inactivo
      moduloGeneral {
        id_modulo
        nombre_modulo
        descripcion_modulo
        is_web
        ruta_admin
      }
    }
  }
`;
export const GET_MODULES_APP = gql`
  query modulosApp(
    $id_empresa: String!
    $id_privilegio: String!
    $id_usuario: String!
  ) {
    modulosApp(
      id_empresa: $id_empresa
      id_privilegio: $id_privilegio
      id_usuario: $id_usuario
    ) {
      id_detalle
      nombre_modulo
      moduloGeneral {
        id_modulo
        nombre_modulo
        descripcion_modulo
        is_web
      }
    }
  }
`;
export const GET_VALORES = gql`
  query valoresReconocimientos {
    valoresReconocimientos {
      id_valor
      nombre_valor
      color
      id_estado_fk
    }
  }
`;
export const GET_DOC_ID = gql`
  query FindDocumentoId($id_documento: String!, $id_empresa: String!) {
    FindDocumentoId(id_documento: $id_documento, id_empresa: $id_empresa) {
      id_documento
      nombre
      url_documento
      id_estado_fk
      id_empresa_fk
      fecha_creacion
      categoria {
        nombre
        id_categoria
      }
    }
  }
`;
export const GET_SAFETY_DOCS = gql`
  query AllDocumentosAdmin(
    $id_empresa: String!
    $id_categoria: String!
    $id_modulo: String!
    $plataforma: String!
    $nombre: String!
  ) {
    AllDocumentosAdmin(
      id_empresa: $id_empresa
      id_categoria: $id_categoria
      id_modulo: $id_modulo
      plataforma: $plataforma
      nombre: $nombre
    ) {
      id_documento
      nombre
      url_documento
      id_estado_fk
      id_empresa_fk
      fecha_creacion
      categoria {
        nombre
        id_categoria
      }
    }
  }
`;
export const GET_SAFETY_DOCS_CATEGORIES = gql`
  query CategoriasDocumentosAdmin($id_empresa: String!, $id_modulo: String!) {
    CategoriasDocumentosAdmin(id_empresa: $id_empresa, id_modulo: $id_modulo) {
      id_categoria
      nombre
      url_imagen
      id_modulo_fk
      id_estado_fk
      id_empresa_fk
    }
  }
`;
export const GET_EMPRESAS_ORGANIGRAMA = gql`
  query CategoriasDocumentos($id_empresa: String!, $id_modulo: String!) {
    CategoriasDocumentos(id_empresa: $id_empresa, id_modulo: $id_modulo) {
      id_categoria
      nombre
      url_imagen
      id_modulo_fk
      id_estado_fk
      id_empresa_fk
    }
  }
`;
export const MODULOS = gql`
  query modulos {
    modulos {
      id_modulo
      nombre_modulo
      descripcion_modulo
      id_admin
      is_web
    }
  }
`;
export const TIPO_BENEFICIO = gql`
  query TipoSolicitudes($id_empresa: String!) {
    TipoSolicitudes(id_empresa: $id_empresa) {
      id_tipo
      nombre_solicitud
      descripcion
      url_imagen
    }
  }
`;
export const DASHBOARD_RECONOCE = gql`
  query DashboardReconocimientos(
    $id_empresa: String!
    $fecha_inicio: String!
    $fecha_final: String!
  ) {
    DashboardReconocimientos(
      id_empresa: $id_empresa
      fecha_inicio: $fecha_inicio
      fecha_final: $fecha_final
    ) {
      total_reconocidos
      total_reconocimientos
      total_valores {
        id_valor
        nombre_valor
        cantidad
        # porcentaje
      }
      total_relaciones {
        id_relacion
        nombre_relacion
        cantidad
        # porcentaje
      }
    }
  }
`;
export const ESTADOS_RECONOCE = gql`
  query ListEstadosSolicitudes {
    ListEstadosSolicitudes {
      id_estado_solicitud
      nombre
      descripcion
      color
    }
  }
`;
export const DASHBOARD_BENEFICIO = gql`
  query DashboardSolicitud(
    $id_empresa: String!
    $historico: String!
    $fecha_inicio: String!
    $fecha_final: String!
  ) {
    DashboardSolicitud(
      id_empresa: $id_empresa
      historico: $historico
      fecha_inicio: $fecha_inicio
      fecha_final: $fecha_final
    ) {
      total_beneficiados
      total_solicitudes
      solicitudes_tipo {
        id_tipo
        nombre_solicitud
        descripcion
        url_imagen
        cantidad_solicitudes
        porcentaje_solicitudes
      }
      solicitudes_estado {
        id_estado_solicitud
        nombre
        descripcion
        color
        cantidad_solicitudes
        porcentaje_solicitudes
      }
    }
  }
`;

export const GET_RECONOCIDOS_MONTH = gql`
  query totalReconocidosYearMonthReconocimientos(
    $id_empresa: String!
    $fecha_inicio: String!
    $fecha_final: String!
  ) {
    totalReconocidosYearMonthReconocimientos(
      id_empresa: $id_empresa
      fecha_inicio: $fecha_inicio
      fecha_final: $fecha_final
    ) {
      id_usuario
      primer_nombre
      segundo_nombre
      apellido_paterno
      apellido_materno
      rut_usuario
      dv_usuario
      imagen_perfil
      genero
      total_reconocimientos
      empresa_cargos {
        id_cargo
        nombre_cargo
      }
      reconocimientos_valor {
        id_valor
        nombre_valor
        cantidad
        color
      }
    }
  }
`;

// Beneficios
export const LIST_ADMIN_BENEFICIO = gql`
  query ListSolicitudesAdmin(
    $id_empresa: String!
    $id_tipo_solicitud: String!
    $historico: String!
    $fecha_inicio: String!
    $fecha_final: String!
    $nombre_usuario: String!
    $id_estado: String!
  ) {
    ListSolicitudesAdmin(
      id_empresa: $id_empresa
      id_tipo_solicitud: $id_tipo_solicitud
      historico: $historico
      fecha_inicio: $fecha_inicio
      fecha_final: $fecha_final
      nombre_usuario: $nombre_usuario
      id_estado: $id_estado
    ) {
      id_solicitud
      codigo_solicitud
      nombre
      descripcion
      fecha_creacion
      estado_solicitud {
        id_estado_solicitud
        nombre
        descripcion
        color
      }
      detalle_solicitud {
        id_solicitud_detalle
        nombre
        descripcion
        cantidad_solicitada
        cantidad_modificada
        cantidad_entregada
        inventario {
          id_inventario
          codigo_inventario
          nombre
          descripcion
          cantidad_inicial
          cantidad_actual
          con_limite
          limite_cantidad
          con_detalle
        }
        inventario_detalle {
          id_detalle
          nombre_detalle
          descripcion
          tipo_detalle
          editable
          id_formulario_fk
          codigo_detalle
        }
        valores_formulario {
          id_valor
          valor
          formulario_selector {
            id_formulario
            nombre
            tipo_dato
            con_valor_predeterminado
            is_selector
            nombre_header
          }
        }
      }
      usuario {
        id_usuario
        primer_nombre
        segundo_nombre
        apellido_paterno
        apellido_materno
        rut_usuario
        dv_usuario
        imagen_perfil
        fecha_ingreso
        cargo {
          id_cargo
          nombre_cargo
        }
      }
      tipo_solicitud {
        id_tipo
        nombre_solicitud
        descripcion
        url_imagen
        datos_formulario {
          id_formulario
          nombre
          tipo_dato
          con_valor_predeterminado
          informacion_usuario
          is_selector
          is_admin

          datos_selector_empresa {
            id_dato_selector
            nombre
            descripcion
            id_formulario_dato_fk
          }
          datos_selector_inventario {
            id_detalle
            nombre_detalle
            descripcion
            tipo_detalle
            editable
            id_formulario_fk
            codigo_detalle
          }
          datos_selector_producto {
            id_inventario
            codigo_inventario
            nombre
            descripcion
            cantidad_inicial
            cantidad_actual
            con_limite
            limite_cantidad
            con_detalle
            detalles_inventario {
              id_detalle
              nombre_detalle
              descripcion
              tipo_detalle
              editable
              id_formulario_fk
              codigo_detalle
            }
          }
        }
      }
    }
  }
`;
export const ELEMENTS_TIPOS_SOLICITUD = gql`
  query ListarAllElementsTiposSolicitudAdmin(
    $id_empresa: String!
    $id_usuario: String!
    $id_tipo_solicitud: String!
  ) {
    ListarAllElementsTiposSolicitudAdmin(
      id_empresa: $id_empresa
      id_usuario: $id_usuario
      id_tipo_solicitud: $id_tipo_solicitud
    ) {
      id_tipo
      nombre_solicitud
      descripcion
      url_imagen
      datos_formulario {
        id_formulario
        nombre
        tipo_dato
        con_valor_predeterminado
        informacion_usuario
        is_selector
        is_admin
        datos_selector_empresa {
          id_dato_selector
          nombre
          descripcion
          id_formulario_dato_fk
        }
        datos_selector_inventario {
          id_detalle
          nombre_detalle
          descripcion
          tipo_detalle
          editable
          id_formulario_fk
          codigo_detalle
        }
        datos_selector_producto {
          id_inventario
          codigo_inventario
          nombre
          descripcion
          cantidad_inicial
          cantidad_actual
          con_limite
          limite_cantidad
          con_detalle
          detalles_inventario {
            id_detalle
            nombre_detalle
            descripcion
            tipo_detalle
            editable
            id_formulario_fk
            codigo_detalle
          }
        }
      }
      datos_inventario {
        id_inventario
        codigo_inventario
        nombre
        descripcion
        cantidad_inicial
        cantidad_actual
        con_limite
        limite_cantidad
        con_detalle
      }
      usuario {
        id_usuario
        primer_nombre
        segundo_nombre
        apellido_paterno
        apellido_materno
        rut_usuario
        dv_usuario
        correo
        telefono_movil
      }
    }
  }
`;
export const PAGINA_LIST_BENEFICIOS = gql`
  query PaginasSolicitudesAdmin(
    $id_empresa: String!
    $id_tipo_solicitud: String!
    $historico: String!
    $fecha_inicio: String!
    $fecha_final: String!
    $nombre_usuario: String!
    $id_estado: String!
    $limite: String!
  ) {
    PaginasSolicitudesAdmin(
      id_empresa: $id_empresa
      id_tipo_solicitud: $id_tipo_solicitud
      historico: $historico
      fecha_inicio: $fecha_inicio
      fecha_final: $fecha_final
      nombre_usuario: $nombre_usuario
      id_estado: $id_estado
      limite: $limite
    ) {
      pagina
      solicitudes
      total
    }
  }
`;

export const LIST_PAGINADA_SOLICIUDES = gql`
  query ListSolicitudesAdminById(
    $id_empresa: String!
    $id_tipo_solicitud: String!
    $id_solicitud: [String!]
  ) {
    ListSolicitudesAdminById(
      id_empresa: $id_empresa
      id_tipo_solicitud: $id_tipo_solicitud
      id_solicitud: $id_solicitud
    ) {
      id_solicitud
      codigo_solicitud
      nombre
      descripcion
      fecha_creacion
      estado_solicitud {
        id_estado_solicitud
        nombre
        descripcion
        color
      }
      detalle_solicitud {
        id_solicitud_detalle
        nombre
        descripcion
        cantidad_solicitada
        cantidad_modificada
        cantidad_entregada
        inventario {
          id_inventario
          codigo_inventario
          nombre
          descripcion
          cantidad_inicial
          cantidad_actual
          con_limite
          limite_cantidad
          con_detalle
        }
        inventario_detalle {
          id_detalle
          nombre_detalle
          descripcion
          tipo_detalle
          editable
          id_formulario_fk
          codigo_detalle
        }
        valores_formulario {
          id_valor
          valor
          formulario_selector {
            id_formulario
            nombre
            tipo_dato
            con_valor_predeterminado
            is_selector
            nombre_header
          }
        }
      }
      usuario {
        id_usuario
        primer_nombre
        segundo_nombre
        apellido_paterno
        apellido_materno
        rut_usuario
        dv_usuario
        imagen_perfil
        fecha_ingreso
        cargo {
          id_cargo
          nombre_cargo
        }
      }
      tipo_solicitud {
        id_tipo
        nombre_solicitud
        descripcion
        url_imagen
        datos_formulario {
          id_formulario
          nombre
          tipo_dato
          con_valor_predeterminado
          informacion_usuario
          is_selector
          is_admin

          datos_selector_empresa {
            id_dato_selector
            nombre
            descripcion
            id_formulario_dato_fk
          }
          datos_selector_inventario {
            id_detalle
            nombre_detalle
            descripcion
            tipo_detalle
            editable
            id_formulario_fk
            codigo_detalle
          }
          datos_selector_producto {
            id_inventario
            codigo_inventario
            nombre
            descripcion
            cantidad_inicial
            cantidad_actual
            con_limite
            limite_cantidad
            con_detalle
            detalles_inventario {
              id_detalle
              nombre_detalle
              descripcion
              tipo_detalle
              editable
              id_formulario_fk
              codigo_detalle
            }
          }
        }
      }
    }
  }
`;
// Beneficios
//VACUNAS
export const LIST_TYPE_VACUNAS = gql`
  query ListTipoVacunas($id_empresa: String!) {
    ListTipoVacunas(id_empresa: $id_empresa) {
      id_tipo
      nombre
      descripcion
    }
  }
`;
export const LIST_VACUNAS_USER = gql`
  query ListVacunasUsuario($id_empresa: String!) {
    ListVacunasUsuario(id_empresa: $id_empresa) {
      id_vacuna
      numero_dosis
      fecha_dosis
      fecha_siguiente_dosis
      url_imagen
      codigo
      usuario {
        id_usuario
        primer_nombre
        segundo_nombre
        apellido_paterno
        apellido_materno
        rut_usuario
        dv_usuario
        correo
      }
      tipo_vacuna {
        id_tipo
        nombre
        descripcion
      }
    }
  }
`;
export const LIST_ALL_VACUNAS = gql`
  query ListaAllVacunas(
    $id_empresa: String!
    $nombre_usuario: String!
    $fecha_inicio: String!
    $fecha_final: String!
  ) {
    ListaAllVacunas(
      id_empresa: $id_empresa
      nombre_usuario: $nombre_usuario
      fecha_inicio: $fecha_inicio
      fecha_final: $fecha_final
    ) {
      usuario {
        id_usuario
        primer_nombre
        segundo_nombre
        apellido_paterno
        apellido_materno
        rut_usuario
        dv_usuario
        imagen_perfil
        fecha_ingreso
        cargo {
          id_cargo
          nombre_cargo
        }
      }
      vacunas {
        id_vacuna
        numero_dosis
        fecha_dosis
        fecha_siguiente_dosis
        url_imagen
        codigo
        usuario {
          id_usuario
          primer_nombre
          segundo_nombre
          apellido_paterno
          apellido_materno
          rut_usuario
          dv_usuario
          correo
        }
        tipo_vacuna {
          id_tipo
          nombre
          descripcion
        }
      }
    }
  }
`;
export const DASHBOARD_VACUNAS = gql`
  query DashboardVacunas($id_empresa: String!) {
    DashboardVacunas(id_empresa: $id_empresa) {
      total_vacunados
      estadisticas_vacunas {
        id_tipo
        nombre
        total_vacunas
        porcentaje_vacunas
      }
    }
  }
`;
export const ENCUSTAS_COVID = gql`
  query EncuestaCovid($id_empresa: String!) {
    EncuestaCovid(id_empresa: $id_empresa) {
      id_encuesta
      nombre_encuesta
      url_encuesta
      fecha_inicio_encuesta
      fecha_termino_encuesta
      fecha_encuesta
      id_empresa_fk

      tipo_encuesta {
        id_tipo_encuesta
        nombre_tipo_encuesta
      }
      preguntas {
        id_pregunta
        texto_pregunta
        editable
        id_estado_fk
        tipo_pregunta {
          id_pregunta_tipo
          nombre_pregunta_tipo
        }
        alternativas {
          id_alternativa
          texto_alternativa
          correcta_alternativa
          genera_qr
          id_pregunta_fk
        }
      }
      segmentaciones {
        id_segmentacion
        tipo_segmentacion {
          id_elementos_segmentacion
          nombre_segmentacion
        }
        segmentaciones
      }
    }
  }
`;
// # enlace_individual(id_usuario: String!): [EnlaceUsuario]
// # confirm_seen(id_usuario: String!): [VistoEncuesta]

export const LIST_REPORTS_COVID = gql`
  query ListAllReportesUser(
    $id_empresa: String!
    $id_encuesta: String!
    $nombre_usuario: String!
    $fecha_inicio: String!
    $fecha_final: String!
  ) {
    ListAllReportesUser(
      id_empresa: $id_empresa
      id_encuesta: $id_encuesta
      nombre_usuario: $nombre_usuario
      fecha_inicio: $fecha_inicio
      fecha_final: $fecha_final
    ) {
      usuario {
        id_usuario
        primer_nombre
        segundo_nombre
        apellido_paterno
        apellido_materno
        rut_usuario
        dv_usuario
        imagen_perfil
        fecha_ingreso
        cargo {
          id_cargo
          nombre_cargo
        }
      }
      reportes {
        id_respuesta
        temperatura
        patente
        fecha_creacion
        id_empresa_fk
        respuestas {
          id_respuesta_detalle
          respuesta
          tiempo_respuesta
          fecha_creacion
          pregunta {
            id_pregunta
            texto_pregunta
            editable
            id_estado_fk
          }
        }
      }
    }
  }
`;

export const GET_MURO = gql`
  query muros($id_empresa: String!, $id_usuario: String!) {
    muros(id_empresa: $id_empresa, id_usuario: $id_usuario) {
      id_muro
      texto
      imagen_muro
      video_muro
      tipo_post
      fijado_orden
      fecha_creacion
      usuario {
        id_usuario
        primer_nombre
        apellido_paterno
        imagen_perfil
      }
      comentarios {
        id_comentario
        texto
        usuario {
          id_usuario
          primer_nombre
          apellido_paterno
          imagen_perfil
        }
        menciones {
          id_mencion
          id_mencionado_fk
        }
        likes {
          id_like
          id_comentario_fk
          id_usuario_fk
        }
      }
      likes {
        id_like
        id_usuario_fk
      }
    }
  }
`;
//CULTURA
export const GET_CULTURAS = gql`
  query culturas($id_empresa: String!) {
    culturas(id_empresa: $id_empresa) {
      id_cultura
      id_estado_fk
      fecha_creacion
      id_empresa_fk
      descripcion {
        id_descripcion
        titulo
        texto
        url_documento
        color_elemento
        id_estado_fk
        tipo_descripcion {
          id_tipo
          nombre
          descripcion
          imagen_url
        }
      }
      valores {
        id_valor
        nombre
        descripcion
        color
        imagen_url
        orden
        id_estado_fk
        id_empresa_fk
      }
    }
  }
`;
export const GET_CULTURA_ID = gql`
  query culturas($id_empresa: String!) {
    culturas(id_empresa: $id_empresa) {
      id_cultura
    }
  }
`;
export const GET_CULTURAS_VALOR = gql`
  query culturas($id_empresa: String!) {
    culturas(id_empresa: $id_empresa) {
      valores {
        id_valor
        nombre
        descripcion
        color
        imagen_url
        orden
        id_estado_fk
        id_empresa_fk
      }
    }
  }
`;

// Query alerta push
export const PAGINA_LIST_NOTIFICACIONES = gql`
  query paginasNotificacionesAdminUpdated(
    $id_empresa: String!
    $estado_notificacion: String!
    $fecha_inicio: String!
    $fecha_fin: String!
    $limite: String!
    $texto_notificacion: String!
  ) {
    paginasNotificacionesAdminUpdated(
      estado_notificacion: $estado_notificacion
      id_empresa: $id_empresa
      fecha_inicio: $fecha_inicio
      fecha_fin: $fecha_fin
      limite: $limite
      texto_notificacion: $texto_notificacion
    ) {
      pagina
      notificaciones
    }
  }
`;

export const LIST_PAGINADA_NOTIFICACIONES = gql`
  query limitNotificacionesAdmin(
    $id_usuario: String!
    $id_empresa: String!
    $id_notificacion: [String!]
  ) {
    limitNotificacionesAdmin(
      id_usuario: $id_usuario
      id_empresa: $id_empresa
      id_notificacion: $id_notificacion
    ) {
      id_notificacion
      titulo_notificacion
      texto_notificacion
      fecha_creacion
      fecha_notificacion
      push_enviado
      id_empresa_fk
      tipo_notificacion {
        id_tipo
        nombre_tipo
      }
      estado_notificacion
      id_modulo_fk
      id_estado_fk
      token_totales
      token_check
      intentos
    }
  }
`;

export const GET_TOTAL_NOTIFICACIONES_ADMIN = gql`
  query TotalNotificacionesAdmin(
    $textoNotificacion: String!
    $idEmpresa: String!
    $fechaInicio: String!
    $fechaFin: String!
  ) {
    TotalNotificacionesAdmin(
      texto_notificacion: $textoNotificacion
      id_empresa: $idEmpresa
      fecha_inicio: $fechaInicio
      fecha_fin: $fechaFin
    ) {
      total
    }
  }
`;

export const GET_ESTADOS_NOTIFICACIONES_ADMIN = gql`
  query EstadosPush {
    EstadosPush {
      estado_notificacion
      nombre_estado
    }
  }
`;

export const GET_ONE_NOTIFICACION = gql`
  query GetOneNotificacion(
    $idUsuario: String!
    $idNotificacion: String!
    $idEmpresa: String!
  ) {
    GetOneNotificacion(
      id_usuario: $idUsuario
      id_notificacion: $idNotificacion
      id_empresa: $idEmpresa
    ) {
      id_notificacion
      titulo_notificacion
      texto_notificacion
      fecha_creacion
      fecha_notificacion
      push_enviado
      id_empresa_fk
      tipo_notificacion {
        id_tipo
        nombre_tipo
      }
      estado_notificacion
      id_modulo_fk
      id_estado_fk
      token_totales
      token_check
      intentos
      segmentacion {
        tipo_segmentacion {
          id_elementos_segmentacion
          nombre_segmentacion
        }
        gerencias {
          id_gerencia
          nombre_gerencia
          bussiness_unit
          unit_number
        }
        sucursales {
          id_sucursal
          nombre_sucursal
        }
        sindicatos {
          id_sindicato
          nombre_sindicato
        }
        generos {
          id_genero
          nombre_genero
        }
      }
    }
  }
`;
// Query alerta push

// Query encuestas admin
export const GET_ESTADOS_ENCUESTA = gql`
  query CategoriasEncuestas {
    CategoriasEncuestas {
      id_categoria
      nombre_categoria
    }
  }
`;

export const GET_ENCUESTAS_ADMIN = gql`
  query listEncuestasAdmin(
    $id_empresa: String!
    $nombre: String!
    $fecha_inicio: String!
    $fecha_fin: String!
    $id_categoria: String!
    $limit: String!
    $last_id: String!
    $last_fecha: String!
  ) {
    listEncuestasAdmin(
      id_empresa: $id_empresa
      nombre: $nombre
      fecha_inicio: $fecha_inicio
      fecha_fin: $fecha_fin
      id_categoria: $id_categoria
      limit: $limit
      last_id: $last_id
      last_fecha: $last_fecha
    ) {
      id_encuesta
      nombre_encuesta
      url_encuesta
      fecha_inicio_encuesta
      fecha_termino_encuesta
      fecha_creacion
      id_empresa_fk
      is_anonima
      estadisticas {
        total_usuarios
        total_respuestas
        porcentaje
      }
      tipo_encuesta {
        id_tipo_encuesta
        nombre_tipo_encuesta
      }
      preguntas {
        id_pregunta
        texto_pregunta
        editable
        is_star
        is_points
        id_estado_fk
        tipo_pregunta {
          id_pregunta_tipo
          nombre_pregunta_tipo
          is_icon
        }
        alternativas {
          id_alternativa
          texto_alternativa
          correcta_alternativa
          genera_qr
          id_pregunta_fk
        }
      }
      segmentaciones {
        id_segmentacion
        tipo_segmentacion {
          id_elementos_segmentacion
          nombre_segmentacion
        }
        segmentaciones
      }
      descripcion
    }
  }
`;

export const PAGINAS_ENCUESTAS_ADMIN = gql`
  query PaginasEncuestasAdmin(
    $id_empresa: String!
    $nombre: String!
    $fecha_inicio: String!
    $fecha_fin: String!
    $id_categoria: String!
    $limite: String!
  ) {
    PaginasEncuestasAdmin(
      id_empresa: $id_empresa
      nombre: $nombre
      fecha_inicio: $fecha_inicio
      fecha_fin: $fecha_fin
      id_categoria: $id_categoria
      limite: $limite
    ) {
      pagina
      reportes
    }
  }
`;

export const GET_ENCUESTAS_PAGINADAS = gql`
  query LimitEncuestasAdmin($id_empresa: String!, $id_encuesta: [String!]) {
    LimitEncuestasAdmin(id_empresa: $id_empresa, id_encuesta: $id_encuesta) {
      id_encuesta
      nombre_encuesta
      url_encuesta
      fecha_inicio_encuesta
      fecha_termino_encuesta
      fecha_creacion
      id_empresa_fk
      is_anonima
      id_categoria
      nombre_categoria
      preguntas {
        id_pregunta
        texto_pregunta
        editable
        is_star
        is_points
        id_estado_fk
        tipo_pregunta {
          id_pregunta_tipo
          nombre_pregunta_tipo
          is_icon
        }
        alternativas {
          id_alternativa
          texto_alternativa
          correcta_alternativa
          genera_qr
          id_pregunta_fk
        }
      }
      enlace_individual {
        id_enlace
        id_usuario_fk
        id_encuesta_fk
        url_enlace
      }
      confirm_seen {
        id_visto
        visto
        id_encuesta_fk
        id_usuario_fk
      }
      segmentaciones {
        id_segmentacion
        tipo_segmentacion {
          id_elementos_segmentacion
          nombre_segmentacion
        }
        segmentaciones
      }
      estadisticas {
        total_usuarios
        total_respuestas
        porcentaje
      }
      tipo_encuesta {
        id_tipo_encuesta
        nombre_tipo_encuesta
      }
      descripcion
    }
  }
`;

export const GET_ENCUESTA_ID = gql`
  query EncuestaIdAdmin($idEmpresa: String!, $idEncuesta: String!) {
    encuestaIdAdmin(id_empresa: $idEmpresa, id_encuesta: $idEncuesta) {
      id_encuesta
      nombre_encuesta
      url_encuesta
      fecha_inicio_encuesta
      fecha_termino_encuesta
      fecha_creacion
      is_anonima
      id_categoria
      nombre_categoria
      preguntas {
        id_pregunta
        texto_pregunta
        editable
        is_star
        is_points
        id_estado_fk
        tipo_pregunta {
          id_pregunta_tipo
          nombre_pregunta_tipo
          is_icon
        }
        alternativas {
          id_alternativa
          texto_alternativa
          correcta_alternativa
          genera_qr
        }
      }
      enlace_individual {
        id_enlace
        url_enlace
      }
      confirm_seen {
        id_visto
        visto
      }
      segmentaciones {
        id_segmentacion
        tipo_segmentacion {
          id_elementos_segmentacion
          nombre_segmentacion
        }
        segmentaciones
      }
      estadisticas {
        total_usuarios
        total_respuestas
        porcentaje
      }
      tipo_encuesta {
        id_tipo_encuesta
        nombre_tipo_encuesta
      }
      descripcion
    }
  }
`;
// Query encuestas admin

// Query Transporte admin
export const GET_DASHBOARD_TRANSPORTE = gql`
  query DashboardTransporte(
    $id_empresa: String!
    $tipo_rango: String!
    $fecha_inicio: String!
    $fecha_fin: String!
  ) {
    DashboardTransporte(
      id_empresa: $id_empresa
      tipo_rango: $tipo_rango
      fecha_inicio: $fecha_inicio
      fecha_fin: $fecha_fin
    ) {
      id_tipo_transporte
      nombre_transporte
      viajes_realizados
      tramos_generados
      ocupacion_historica
      ocupacion_activa
      solicitudes_historicas
      solicitudes_activas
      proximas_fechas {
        fecha_viaje
        cantidad_tramos
      }
    }
  }
`;

export const GET_WALL = gql`
  query PublicacionesMuro(
    $id_empresa: String!
    $id_usuario: String!
    $plataforma: String!
    $fecha_inicio: String!
    $fecha_final: String!
    $nombre: String!
    $last_id: String!
    $limit: String!
  ) {
    PublicacionesMuro(
      id_empresa: $id_empresa
      id_usuario: $id_usuario
      plataforma: $plataforma
      fecha_inicio: $fecha_inicio
      fecha_final: $fecha_final
      nombre: $nombre
      last_id: $last_id
      limit: $limit
    ) {
      id_muro
      texto
      imagen_muro
      video_muro
      tipo_post
      fijado_orden
      fecha_creacion
      id_estado_fk
      usuario {
        primer_nombre
        apellido_paterno
        imagen_perfil
        id_usuario
      }
      perfil {
        id_perfil
        nombre_perfil
        color
        imagen_perfil
        id_estado_fk
      }
      comentarios_compartidos {
        id_comentario
        texto
        fecha_creacion
        usuario {
          id_usuario
          primer_nombre
          apellido_paterno
        }
        mencionados {
          usuario_comentario {
            id_usuario
            primer_nombre
            apellido_paterno
          }
          id_mencion
          usuario_mencionado {
            id_usuario
            primer_nombre
            apellido_paterno
          }
        }
        likes {
          id_like
        }
        respuestas_comentarios {
          id_comentario
          texto
          fecha_creacion
          usuario {
            id_usuario
            primer_nombre
            apellido_paterno
          }
          mencionados {
            usuario_comentario {
              id_usuario
              primer_nombre
              apellido_paterno
            }
            id_mencion
            usuario_mencionado {
              id_usuario
              primer_nombre
              apellido_paterno
            }
          }
          likes {
            id_like
          }
        }
      }
      likes {
        id_like
      }
      compartido {
        id_compartido
        id_modulo_solicitador
        id_modulo_compartido
        id_objeto
        id_objeto_compartido
        noticia {
          id_noticia
          titulo_noticia
        }
        galeria {
          id_galeria
          titulo_galeria
        }
      }
    }
  }
`;

export const GET_POSTS_PAGINATION = gql`
  query TotalPaginasPublicacionesMuro(
    $id_empresa: String!
    $id_usuario: String!
    $plataforma: String!
    $fecha_inicio: String!
    $fecha_final: String!
    $nombre: String!
    $limite: String!
  ) {
    TotalPaginasPublicacionesMuro(
      id_empresa: $id_empresa
      id_usuario: $id_usuario
      plataforma: $plataforma
      fecha_inicio: $fecha_inicio
      fecha_final: $fecha_final
      nombre: $nombre
      limite: $limite
    ) {
      pagina
      muros
    }
  }
`;

export const GET_POSTS_BY_PAGE = gql`
  query PublicacionesMuroPaginas(
    $id_empresa: String!
    $id_muro: [String!]
    $plataforma: String!
  ) {
    PublicacionesMuroPaginas(
      id_empresa: $id_empresa
      id_muro: $id_muro
      plataforma: $plataforma
    ) {
      id_muro
      texto
      imagen_muro
      video_muro
      tipo_post
      fijado_orden
      fecha_creacion
      id_estado_fk
      usuario {
        primer_nombre
        apellido_paterno
        imagen_perfil
        id_usuario
      }
      perfil {
        id_perfil
        nombre_perfil
        color
        imagen_perfil
        id_estado_fk
      }
      comentarios_compartidos {
        id_comentario
        texto
        fecha_creacion
        usuario {
          id_usuario
          primer_nombre
          apellido_paterno
        }
        mencionados {
          usuario_comentario {
            id_usuario
            primer_nombre
            apellido_paterno
          }
          id_mencion
          usuario_mencionado {
            id_usuario
            primer_nombre
            apellido_paterno
          }
        }
        likes {
          id_like
        }
        respuestas_comentarios {
          id_comentario
          texto
          fecha_creacion
          usuario {
            id_usuario
            primer_nombre
            apellido_paterno
          }
          mencionados {
            usuario_comentario {
              id_usuario
              primer_nombre
              apellido_paterno
            }
            id_mencion
            usuario_mencionado {
              id_usuario
              primer_nombre
              apellido_paterno
            }
          }
          likes {
            id_like
          }
        }
      }
      likes {
        id_like
      }
      compartido {
        id_compartido
        id_modulo_solicitador
        id_modulo_compartido
        id_objeto
        id_objeto_compartido
        noticia {
          id_noticia
          titulo_noticia
        }
        galeria {
          id_galeria
          titulo_galeria
        }
      }
    }
  }
`;

export const GET_WALL_PROFILES = gql`
  query PerfilesListAll(
    $id_empresa: String!
    $limit: String!
    $last_id: String!
  ) {
    PerfilesListAll(id_empresa: $id_empresa, limit: $limit, last_id: $last_id) {
      id_perfil
      nombre_perfil
      color
      imagen_perfil
      id_estado_fk
    }
  }
`;

export const GET_TRAMOS_EMPRESA = gql`
  query TramosEmpresa($id_empresa: String!) {
    tramosEmpresa(id_empresa: $id_empresa) {
      id_destino
      nombre_origen
      nombre_destino
      transporte_tipo_medio {
        id_medio
        nombre_medio
        cantidad_asiento
        transporte_tipo {
          id_tipo
          nombre_transporte
        }
      }
      is_conexion
      ida_vuelta
    }
  }
`;

export const GET_TIPO_MEDIOS = gql`
  query TipoMedios($id_tipo: String!, $id_empresa: String!) {
    tipoMedios(id_tipo: $id_tipo, id_empresa: $id_empresa) {
      id_medio
      nombre_medio
      cantidad_asiento
      transporte_tipo {
        id_tipo
        nombre_transporte
      }
    }
  }
`;

export const GET_ALL_PLANIFICACIONES = gql`
  query GetAllPlanificaciones($idEmpresa: String!) {
    getAllPlanificaciones(id_empresa: $idEmpresa) {
      id_planificacion
      nombre_planificacion
      transporte_planificacion_cadencia {
        id_cadencia
        nombre_cadencia
      }
      transporte_tramo {
        id_tramo
        fecha_viaje
        hora_viaje
        codigo
        codigo_asientos
      }
    }
  }
`;

export const GET_CADENCIA_PLANIFICACION = gql`
  query CadenciaPlanificacion {
    cadenciaPlanificacion {
      id_cadencia
      nombre_cadencia
    }
  }
`;

export const GET_ONE_PLANIFICACION_ID = gql`
  query GetOnePlanificacionID($idEmpresa: String!, $idPlanificacion: String!) {
    getOnePlanificacionID(
      id_empresa: $idEmpresa
      id_planificacion: $idPlanificacion
    ) {
      id_planificacion
      nombre_planificacion
      transporte_planificacion_cadencia {
        id_cadencia
        nombre_cadencia
      }
      tranpsorte_planificacion_detalle {
        id_detalle
        concurrencia_ejecucion_semanal
        concurrencia_ejecucion_day
        concurrencia_ejecucion_month
        concurrencia_tiempo
        inicio_periodo_semanal
        inicio_periodo_day
        inicio_periodo_month
        hora_inicio_periodo
        final_periodo_semanal
        final_periodo_day
        final_periodo_month
        hora_final_periodo
        fecha_inicio_ejecucion
        fecha_final_ejecucion
      }
      transporte_tramo {
        id_tramo
        fecha_viaje
        hora_viaje
        transporte_lista_destino {
          id_destino
          nombre_origen
          nombre_destino
          transporte_tipo_medio {
            id_medio
            nombre_medio
            cantidad_asiento
            transporte_tipo {
              id_tipo
              nombre_transporte
            }
          }
        }
        codigo
        codigo_asientos
      }
    }
  }
`;

export const GET_VIAJES_EN_CURSO = gql`
  query ViajesEnCurso(
    $idEmpresa: String!
    $tipoRango: String!
    $fechaInicio: String!
    $fechaFin: String!
    $limit: String!
    $fechaId: String!
  ) {
    ViajesEnCurso(
      id_empresa: $idEmpresa
      tipo_rango: $tipoRango
      fecha_inicio: $fechaInicio
      fecha_fin: $fechaFin
      limit: $limit
      fecha_id: $fechaId
    ) {
      fecha_viaje
      asientos_totales
      asientos_ocupados
      asientos_porcentaje
      transporte_planificacion {
        id_planificacion
        nombre_planificacion
        fecha_fin_inscripcion
        fecha_inicio_inscripcion
        tranpsorte_planificacion_detalle {
          id_detalle
        }
        transporte_planificacion_cadencia {
          id_cadencia
          nombre_cadencia
        }
      }
    }
  }
`;

export const GET_TRAMOS_VIAJES_EN_CURSO = gql`
  query TramosViajeEnCurso(
    $idEmpresa: String!
    $fechaViaje: String!
    $idPlanificacion: String!
  ) {
    TramosViajeEnCurso(
      id_empresa: $idEmpresa
      fecha_viaje: $fechaViaje
      id_planificacion: $idPlanificacion
    ) {
      id_tramo
      transporte_ida_vuelta {
        id_ida_vuelta
        nombre_idea_vuelta
      }
      transporte_lista_destino {
        id_destino
        nombre_origen
        nombre_destino
        is_conexion
        transporte_tipo_medio {
          nombre_medio
          transporte_tipo {
            nombre_transporte
          }
        }
      }
      transporte_solicitud {
        id_solicitud
        transporte_ida_vuelta {
          id_ida_vuelta
          nombre_idea_vuelta
        }
        transporte_asiento_conexion {
          id_asiento
          nombre_asiento
        }
        usuario {
          id_usuario
          primer_nombre
          apellido_paterno
          rut_usuario
          apellido_materno
          dv_usuario
          correo
          telefono_movil
          telefono_fijo
          imagen_perfil
          cargo {
            id_cargo
            nombre_cargo
          }
          gerencia {
            id_gerencia
            nombre_gerencia
            bussiness_unit
            unit_number
          }
        }
        transporte_asiento {
          id_asiento
          nombre_asiento
        }
      }
      hora_viaje
    }
  }
`;

export const GET_USUARIOS = gql`
  query FindUserSolicitud($idEmpresa: String!, $nombreUsuario: String!) {
    FindUserSolicitud(id_empresa: $idEmpresa, nombre_usuario: $nombreUsuario) {
      usuario {
        id_usuario
        primer_nombre
        apellido_paterno
        rut_usuario
        apellido_materno
        dv_usuario
        correo
        telefono_movil
        telefono_fijo
        imagen_perfil
        cargo {
          id_cargo
          nombre_cargo
        }
        gerencia {
          id_gerencia
          nombre_gerencia
          bussiness_unit
          unit_number
        }
      }
      solicitudes {
        id_solicitud
      }
    }
  }
`;

export const GET_PLANIFICACIONES_APP = gql`
  query GetPlanificacionesApp($idEmpresa: String!) {
    GetPlanificacionesApp(id_empresa: $idEmpresa) {
      id_planificacion
      nombre_planificacion
      fecha_inicio_inscripcion
      fecha_fin_inscripcion
      fecha_viaje
      hora_viaje
    }
  }
`;

export const GET_TURNOS = gql`
  query Turnos($idEmpresa: String!) {
    turnos(id_empresa: $idEmpresa) {
      id_turno
      nombre_turno
    }
  }
`;

export const GET_TEAMS = gql`
  query Teams($idEmpresa: String!) {
    teams(id_empresa: $idEmpresa) {
      id_team
      nombre_team
    }
  }
`;

export const GET_TIPO_TRANSPORTE = gql`
  query TipoTransporteEmpresa(
    $idEmpresa: String!
    $idPlanificacion: String!
    $fechaViaje: String!
  ) {
    tipoTransporteEmpresa(
      id_empresa: $idEmpresa
      id_planificacion: $idPlanificacion
      fecha_viaje: $fechaViaje
    ) {
      id_tipo
      nombre_transporte
      total_tramos
    }
  }
`;

export const GET_TRAMOS_APP = gql`
  query GetTramosApp(
    $idEmpresa: String!
    $idPlanificacion: String!
    $fechaViaje: String!
    $idTipoTransporte: String!
    $isPrincipal: String!
    $idTramoPadre: String!
  ) {
    GetTramosApp(
      id_empresa: $idEmpresa
      id_planificacion: $idPlanificacion
      fecha_viaje: $fechaViaje
      id_tipo_transporte: $idTipoTransporte
      is_principal: $isPrincipal
      id_tramo_padre: $idTramoPadre
    ) {
      id_tramo
      codigo
      hora_viaje
      fecha_viaje
      transporte_ida_vuelta {
        id_ida_vuelta
        nombre_idea_vuelta
      }
      tipo_tramo
      transporte_lista_destino {
        id_destino
        nombre_origen
        nombre_destino
        is_conexion
        transporte_tipo_medio {
          id_medio
          nombre_medio
          cantidad_asiento
        }
      }
      asientos {
        id_asiento
        nombre_asiento
        disponible
      }
    }
  }
`;
export const GET_TRAMOS_ADMIN = gql`
  query GetTramosAdmin(
    $idEmpresa: String!
    $idPlanificacion: String!
    $fechaViaje: String!
    $isPrincipal: String!
    $idTramoPadre: String!
  ) {
    GetTramosAdmin(
      id_empresa: $idEmpresa
      id_planificacion: $idPlanificacion
      fecha_viaje: $fechaViaje
      is_principal: $isPrincipal
      id_tramo_padre: $idTramoPadre
    ) {
      id_tramo
      codigo
      hora_viaje
      fecha_viaje
      transporte_ida_vuelta {
        id_ida_vuelta
        nombre_idea_vuelta
      }
      tipo_tramo
      transporte_lista_destino {
        id_destino
        nombre_origen
        nombre_destino
        is_conexion
        ida_vuelta
        transporte_tipo_medio {
          id_medio
          nombre_medio
          cantidad_asiento
          transporte_tipo {
            id_tipo
            nombre_transporte
          }
        }
      }
      asientos {
        id_asiento
        nombre_asiento
        disponible
      }
    }
  }
`;



export const GET_TIPO_IDA_VUELTA = gql`
  query TipoIdaVuelta {
    tipoIdaVuelta {
      id_ida_vuelta
      nombre_idea_vuelta
    }
  }
`;

export const GET_TRAMOS_APP_VUELTA = gql`
  query GetTramosAppVuelta(
    $idEmpresa: String!
    $idPlanificacion: String!
    $fechaViaje: String!
    $idTipoTransporte: String!
    $idTramoIda: String!
  ) {
    GetTramosAppVuelta(
      id_empresa: $idEmpresa
      id_planificacion: $idPlanificacion
      fecha_viaje: $fechaViaje
      id_tipo_transporte: $idTipoTransporte
      id_tramo_ida: $idTramoIda
    ) {
      id_tramo
      codigo
      hora_viaje
      fecha_viaje
      codigo_asientos
      transporte_ida_vuelta {
        id_ida_vuelta
        nombre_idea_vuelta
      }
      tipo_tramo
      transporte_lista_destino {
        id_destino
        nombre_origen
        nombre_destino
        is_conexion
        transporte_tipo_medio {
          id_medio
          nombre_medio
          cantidad_asiento
          transporte_tipo {
            id_tipo
            nombre_transporte
          }
        }
      }
      asientos {
        id_asiento
        nombre_asiento
        disponible
      }
    }
  }
`;

export const GET_FIND_SOLICITUDES = gql`
  query FindSolicitudesRecientesUsuario(
    $idEmpresa: String!
    $idUsuario: String!
  ) {
    FindSolicitudesRecientesUsuario(
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
    ) {
      id_solicitud
      fecha_creacion
      error
      guardado
      ida_vuelta
      usuario {
        id_usuario
        primer_nombre
        apellido_paterno
        apellido_materno
        imagen_perfil
        turno {
          id_turno
          nombre_turno
        }
        team {
          id_team
          nombre_team
        }
        rut_usuario
        dv_usuario
        cargo {
          nombre_cargo
        }
        gerencia {
          nombre_gerencia
        }
        telefono_movil
        correo
      }
      transporte_tramo {
        id_tramo
        codigo
        codigo_asientos
        fecha_viaje
        hora_viaje
        transporte_lista_destino {
          id_destino
          nombre_origen
          nombre_destino
          transporte_tipo_medio {
            id_medio
            nombre_medio
            cantidad_asiento
            transporte_tipo {
              id_tipo
              nombre_transporte
            }
          }
        }
        transporte_planificacion {
          id_planificacion
          nombre_planificacion
        }
      }
      transporte_asiento {
        id_asiento
        nombre_asiento
      }
      transporte_tramo_conexion {
        id_tramo
        codigo
        codigo_asientos
        transporte_lista_destino {
          id_destino
          nombre_origen
          nombre_destino
          transporte_tipo_medio {
            id_medio
            cantidad_asiento
          }
        }
        asientos {
          id_asiento
          nombre_asiento
          disponible
        }
      }
      transporte_asiento_conexion {
        id_asiento
        nombre_asiento
      }
    }
  }
`;

export const GET_ESTADISTICAS = gql`
  query ModuloEstadisticaInd(
    $idEmpresa: String!
    $idModulo: String!
    $idFiltro: String!
    $fechaInicio: String!
    $fechaFin: String!
  ) {
    ModuloEstadisticaInd(
      id_empresa: $idEmpresa
      id_modulo: $idModulo
      id_filtro: $idFiltro
      fecha_inicio: $fechaInicio
      fecha_fin: $fechaFin
    ) {
      modulo {
        id_detalle
        nombre_modulo
        descripcion_modulo
        posicion_pantalla
        imagen_url
        url_menu_inactivo
        url_menu_activo
        ruta_web
        moduloGeneral {
          id_modulo
          nombre_modulo
          descripcion_modulo
          id_app
          id_admin
          is_web
        }
      }
      estadisticas {
        id
        nombre
        url
        total
        show
      }
    }
  }
`;

export const TOP_NOTICIAS = gql`
  query noticiasTopVistasPeriodo(
    $id_empresa: String!
    $id_filtro: String!
    $fecha_inicio: String!
    $fecha_fin: String!
  ) {
    noticiasTopVistasPeriodo(
      id_empresa: $id_empresa
      id_filtro: $id_filtro
      fecha_inicio: $fecha_inicio
      fecha_fin: $fecha_fin
      id_usuario: "2"
    ) {
      id_noticia
      titulo_noticia
      url_imagen_principal
      fecha_noticia
      fecha_creacion
      alerta_push
      tipo_noticia {
        id_tipo
        nombre_tipo
      }
      categoria {
        id_categoria
        nombre_categoria
        especial
      }
      contenido {
        id_contenido
        id_noticia_fk
        texto
        url_imagen
        url_externo_app
        url_externo_intranet
        url_video
        url_documento
        usuario {
          primer_nombre
          apellido_paterno
          apellido_materno
        }
        tipo_contenido {
          id_tipo_contenido
          nombre_tipo_contenido
        }
      }
      likes {
        id_like
      }
      vistos {
        id_visto
        id_usuario_fk
      }
      segmentaciones {
        id_segmentacion
        tipo_segmentacion {
          id_elementos_segmentacion
          nombre_segmentacion
        }
        segmentaciones
      }
    }
  }
`;

export const TOP_VIDEO = gql`
  query videosTopPeriodo(
    $id_empresa: String!
    $id_filtro: String!
    $fecha_inicio: String!
    $fecha_fin: String!
  ) {
    videosTopPeriodo(
      id_empresa: $id_empresa
      id_filtro: $id_filtro
      fecha_inicio: $fecha_inicio
      fecha_fin: $fecha_fin
      id_usuario: "2"
    ) {
      id_video
      titulo_video
      descripcion_video
      url_imagen
      vistos {
        id_visto
      }
      likes_global {
        id_like
      }
      likes {
        id_like
      }
      categoria {
        id_categoria
      }
    }
  }
`;

export const TOP_GALERIAS = gql`
  query galeriasTopPeriodo(
    $id_empresa: String!
    $id_filtro: String!
    $fecha_inicio: String!
    $fecha_fin: String!
  ) {
    galeriasTopPeriodo(
      id_empresa: $id_empresa
      id_filtro: $id_filtro
      fecha_inicio: $fecha_inicio
      fecha_fin: $fecha_fin
      id_usuario: "2"
    ) {
      id_galeria
      titulo_galeria
      id_estado_fk
      nombre_carpeta
      total_likes_admin
      id_empresa_fk
      fecha_galeria
      alerta_push
      imagenes {
        id_imagen
        url_imagen_original
        url_imagen_thumb
        id_empresa_fk
        likes {
          id_like
          usuario {
            primer_nombre
            segundo_nombre
            apellido_paterno
          }
        }
        confirm_like {
          id_like
          usuario {
            primer_nombre
            segundo_nombre
            apellido_paterno
          }
        }
      }
      vistos {
        id_visto
        usuario {
          primer_nombre
          segundo_nombre
          apellido_paterno
        }
      }
      segmentaciones {
        id_segmentacion
        tipo_segmentacion {
          id_elementos_segmentacion
          nombre_segmentacion
        }
        segmentaciones
      }
    }
  }
`;

export const ESTADISTICAS_GENERALES_MODULOS = gql`
  query EstadisticasGeneralesModulosFilter(
    $id_empresa: String!
    $fecha: String!
  ) {
    EstadisticasGeneralesModulosFilter(
      id_empresa: $id_empresa
      id_filtro: "1"
      fecha_inicio: $fecha
      fecha_fin: $fecha
    ) {
      vistos
      modulo {
        id_detalle
        nombre_modulo
        descripcion_modulo
        posicion_pantalla
        imagen_url
        url_menu_inactivo
        url_menu_activo
        ruta_web
        moduloGeneral {
          id_modulo
          nombre_modulo
          descripcion_modulo
          id_app
          id_admin
          is_web
        }
      }
    }
  }
`;
export const TOTAL_VIDEOS = gql`
  query PaginacionTotalVideosAdmin(
    $idEmpresa: String!
    $nombre: String!
    $fechaInicio: String!
    $fechaFinal: String!
    $idCategoria: String!
    $limite: String!
  ) {
    paginacionTotalVideosAdmin(
      id_empresa: $idEmpresa
      nombre: $nombre
      fecha_inicio: $fechaInicio
      fecha_final: $fechaFinal
      id_categoria: $idCategoria
      limite: $limite
    ) {
      pagina
      videos
    }
  }
`;
export const VIDEOS_ARR = gql`
  query VideosAdmin($idEmpresa: String!, $idVideo: [String]) {
    videosAdmin(id_empresa: $idEmpresa, id_video: $idVideo) {
      id_video
      titulo_video
      descripcion_video
      nombre_carpeta
      url_video
      url_imagen
      id_empresa_fk
      id_estado_fk
      fecha_video
      fecha_creacion
      categoria {
        id_categoria
        nombre_categoria
        id_empresa_fk
      }
      likes
      count_likes_global
      segmentaciones {
        id_segmentacion
        tipo_segmentacion{
          id_elementos_segmentacion
          nombre_segmentacion
        }
        segmentaciones
      }
      alerta_push
    }
  }
`;
export const VIDEOS = gql`
  query VideosPaginadosAdmin(
    $idEmpresa: String!
    $nombre: String!
    $fechaInicio: String!
    $fechaFin: String!
    $idCategoria: String!
    $lastId: String!
    $limit: String!
  ) {
    videosPaginadosAdmin(
      id_empresa: $idEmpresa
      nombre: $nombre
      fecha_inicio: $fechaInicio
      fecha_fin: $fechaFin
      id_categoria: $idCategoria
      last_id: $lastId
      limit: $limit
    ) {
      id_video
      titulo_video
      descripcion_video
      nombre_carpeta
      url_video
      url_imagen
      id_empresa_fk
      id_estado_fk
      categoria {
        id_categoria
        nombre_categoria
        id_empresa_fk
      }
      likes
      fecha_creacion
      fecha_video
      segmentaciones {
        tipo_segmentacion {
          id_elementos_segmentacion
          nombre_segmentacion
        }
        id_segmentacion
        segmentaciones
      }
      alerta_push
    }
  }
`;
export const VIDEO = gql`
  query Video($idEmpresa: String!, $idVideo: String!, $idUsuario: String!) {
    video(id_empresa: $idEmpresa, id_video: $idVideo, id_usuario: $idUsuario) {
      id_video
      titulo_video
      descripcion_video
      nombre_carpeta
      url_video
      url_imagen
      id_empresa_fk
      id_estado_fk
      alerta_push
      fecha_video
      categoria {
        id_categoria
        nombre_categoria
        id_empresa_fk
      }
    }
  }
`;

export const REPORTE_ENCUESTAS_ADMIN = gql`
  query reportesEncuestasAdmin($idEmpresa: String!, $idEncuesta: String!) {
    reportesEncuestasAdmin(id_empresa: $idEmpresa, id_encuesta: $idEncuesta) {
      id_encuesta
      is_anonima
      nombre_encuesta
      url_encuesta
      id_empresa_fk
      id_tipo_encuesta_fk
      preguntas {
        id_pregunta
        texto_pregunta
        is_star
        is_points
        alternativas {
          id_alternativa
          texto_alternativa
          correcta_alternativa
        }
        tipo_pregunta {
          id_pregunta_tipo
          nombre_pregunta_tipo
          is_icon
        }
      }
      respuestas {
        id_respuesta
        usuario {
          rut_usuario
          dv_usuario
          primer_nombre
          apellido_paterno
          cargo {
            id_cargo
            nombre_cargo
          }
          gerencia {
            id_gerencia
            nombre_gerencia
          }
        }
        respuestas {
          respuesta
          id_respuesta_detalle
          tiempo_respuesta
          pregunta {
            id_pregunta
            is_star
            texto_pregunta
            tipo_pregunta {
              id_pregunta_tipo
              nombre_pregunta_tipo
              is_icon
            }
          }
        }
      }
    }
  }
`;

export const GRAFICO_ENCUESTA = gql`
  query GraficoEncuesta($id_empresa: String!, $id_encuesta: String!) {
    GraficoEncuesta(id_empresa: $id_empresa, id_encuesta: $id_encuesta) {
      id_encuesta
      nombre_encuesta
      descripcion
      url_encuesta
      fecha_inicio_encuesta
      fecha_termino_encuesta
      fecha_creacion
      id_empresa_fk
      is_anonima
      alerta_push
      id_categoria
      nombre_categoria
      id_tipo_encuesta_fk
      preguntas {
        id_pregunta
        texto_pregunta
        editable
        is_star
        is_points
        id_estado_fk
        total
        id_pregunta_tipo_fk
        encuesta_pregunta_alternativas {
          id_alternativa
          texto_alternativa
          correcta_alternativa
          genera_qr
          id_pregunta_fk
          total_respuestas
        }
      }
    }
  }
`;

export const VIDEOS_CATEGORIAS = gql`
  query CategoriasVideos($idEmpresa: String!, $plataforma: String!) {
    categoriasVideos(id_empresa: $idEmpresa, plataforma: $plataforma) {
      id_categoria
      nombre_categoria
      id_empresa_fk
    }
  }
`;

export const GET_TRAMOS_PAGINATION = gql`
  query TotalTramosPaginados($id_empresa: String!, $limite: String!) {
    totalTramosPaginados(id_empresa: $id_empresa, limite: $limite) {
      pagina
      tramos
    }
  }
`;

export const GET_TRAMOS_TOTAL_PAGINATION = gql`
  query ListTramosPaginados($id_empresa: String!, $id_tramo: [String!]) {
    listTramosPaginados(id_empresa: $id_empresa, id_tramo: $id_tramo) {
      id_destino
      nombre_origen
      nombre_destino
      transporte_tipo_medio {
        id_medio
        nombre_medio
        cantidad_asiento
        transporte_tipo {
          id_tipo
          nombre_transporte
        }
      }
      is_conexion
      ida_vuelta
    }
  }
`;

export const GET_SOLICITUDES_PAGINATION = gql`
  query PaginacionUserSolicitud(
    $id_empresa: String!
    $nombre_usuario: String!
    $limite: String!
  ) {
    PaginacionUserSolicitud(
      id_empresa: $id_empresa
      nombre_usuario: $nombre_usuario
      limite: $limite
    ) {
      pagina
      usuarios
    }
  }
`;

export const GET_SOLICITUDES_TOTAL_PAGINATION = gql`
  query FindUserSolicitudPaginadas(
    $id_empresa: String!
    $id_usuario: [String!]
  ) {
    FindUserSolicitudPaginadas(
      id_empresa: $id_empresa
      id_usuario: $id_usuario
    ) {
      usuario {
        id_usuario
        primer_nombre
        segundo_nombre
        apellido_materno
        apellido_paterno
        rut_usuario
        dv_usuario
        correo
        telefono_movil
        telefono_fijo
        imagen_perfil
        cargo {
          id_cargo
          nombre_cargo
        }  
        turno {
          id_turno
          nombre_turno
        }
        team {
          id_team
          nombre_team
        }
        gerencia {
          id_gerencia
          nombre_gerencia
          bussiness_unit
          unit_number
        }
      }
      solicitudes {
        id_solicitud
      }
    }
  }
`;

export const GET_PLANIFICACIONES_PAGINATION = gql`
  query TotalPlanificacionesAdmin($id_empresa: String!, $limite: String!) {
    totalPlanificacionesAdmin(id_empresa: $id_empresa, limite: $limite) {
      pagina
      planificaciones
    }
  }
`;

export const GET_PLANIFICACIONES_TOTAL_PAGINATION = gql`
  query GetAllPlanificacionesLimit(
    $id_empresa: String!
    $id_planificacion: [String!]
  ) {
    getAllPlanificacionesLimit(
      id_empresa: $id_empresa
      id_planificacion: $id_planificacion
    ) {
      id_planificacion
      nombre_planificacion
      transporte_planificacion_cadencia {
        id_cadencia
        nombre_cadencia
      }
      tranpsorte_planificacion_detalle {
        id_detalle
        concurrencia_ejecucion_semanal
        concurrencia_ejecucion_day
        concurrencia_ejecucion_month
        concurrencia_tiempo
        inicio_periodo_semanal
        inicio_periodo_day
        inicio_periodo_month
        hora_inicio_periodo
        final_periodo_semanal
        final_periodo_day
        final_periodo_month
        hora_final_periodo
        fecha_inicio_ejecucion
        fecha_final_ejecucion
      }
      transporte_tramo {
        id_tramo
        fecha_viaje
        hora_viaje
        fecha_inicio_inscripcion
        fecha_fin_inscripcion
        hora_inicio_inscripcion
        hora_fin_inscripcion
        transporte_lista_destino {
          id_destino
          nombre_origen
          nombre_destino
          transporte_tipo_medio {
            id_medio
            nombre_medio
            cantidad_asiento
            transporte_tipo {
              id_tipo
              nombre_transporte
            }
          }
        }
        codigo
        codigo_asientos
      }
    }
  }
`;
export const GET_GRAFICO_USUARIO_SESION = gql`
  query GetGraficoUsuariosSesion($id_empresa: String!, $fecha: String!) {
    GetGraficoUsuariosSesion(
      id_empresa: $id_empresa
      id_filtro: "1"
      fecha_inicio: $fecha
      fecha_fin: $fecha
    ) {
      total_usuario
      datos_grafico {
        id
        month
        month_number
        month_js
        year
        total
        percentage
        is_current
      }
    }
  }
`;
export const GET_DATA_EXCEL_HOME = gql`
  query ListUsuariosSesion(
    $id_empresa: String!
    $id_filtro: String!
    $fecha_inicio: String!
    $fecha_fin: String!
  ) {
    ListUsuariosSesion(
      id_empresa: $id_empresa
      id_filtro: $id_filtro
      fecha_inicio: $fecha_inicio
      fecha_fin: $fecha_fin
    ) {
      id_usuario
      rut_usuario
      dv_usuario
      primer_nombre
      segundo_nombre
      apellido_paterno
      apellido_materno
      cargo {
        id_cargo
        nombre_cargo
      }
      sucursal {
        id_sucursal
        nombre_sucursal
      }
      gerencia {
        id_gerencia
        nombre_gerencia
      }
    }
  }
`;

// Canales
export const GET_CANALES = gql`
  query GetListCanales($id_estado: Int!) {
    GetListCanales(id_estado: $id_estado) {
      id_canal
      nombre_canal
      descripcion_canal
      reproduccion_dia
      reproduccion_hora
      frecuencia {
        id_frecuencia
        num_dia
        string_dia
        hora_inicio
        hora_fin
      }
      estado {
        id_estado
        id_estado_real
        nombre_estado
        color
      }
      cantidad_pantallas
      fecha_modificacion
    }
  }
`;

//PANTALLAS
export const GET_LIST_SCREENS = gql`
  query GetPantallaList {
    GetPantallaList {
      id_pantalla
      nombre_pantalla
      codigo
      fecha_conexion
      ubicacion
      zona_horaria
      descripcion_pantalla
      estado {
        id_estado
        nombre_estado
        color
      }
      canal {
        id_canal
        nombre_canal
        cantidad_pantallas
        estado {
          id_estado
          nombre_estado
          color
        }
        frecuencia {
          id_frecuencia
        }
        lista_reproduccion {
          id_lista
          url_logo
          videos {
            id_file
            archivo {
              id_archivo
              url_archivo
              detalle_tipoa {
                id_detalle
                tipo_archivo {
                  id_tipo
                  nombre_archivo
                }
              }
            }
            video_tv {
              id_video
              url_video
            }
          }
          banners {
            id_file
            archivo {
              id_archivo
              url_archivo
              detalle_tipoa {
                id_detalle
                tipo_archivo {
                  id_tipo
                  nombre_archivo
                }
              }
            }
            video_tv {
              id_video
              url_video
            }
          }
        }
      }
    }
  }
`;

// tipo_archivo = "1" para videos y "2" para banners

export const GET_ONE_SCREENS = gql`
  query GetPantallaOne($id_pantalla: Int!) {
    GetPantallaOne(id_pantalla: $id_pantalla) {
      id_pantalla
      nombre_pantalla
      codigo
      fecha_conexion
      ubicacion
      zona_horaria
      descripcion_pantalla
      estado {
        id_estado
        nombre_estado
      }
      canal {
        id_canal
        nombre_canal
        cantidad_pantallas
        estado {
          id_estado
          nombre_estado
          color
        }
        frecuencia {
          id_frecuencia
        }
        lista_reproduccion {
          id_lista
          url_logo
          videos {
            id_file
            archivo {
              id_archivo
              url_archivo
              detalle_tipoa {
                id_detalle
                tipo_archivo {
                  id_tipo
                  nombre_archivo
                }
              }
            }
            video_tv {
              id_video
              url_video
            }
          }
          banners {
            id_file
            archivo {
              id_archivo
              url_archivo
              detalle_tipoa {
                id_detalle
                tipo_archivo {
                  id_tipo
                  nombre_archivo
                }
              }
            }
            video_tv {
              id_video
              url_video
            }
          }
        }
      }
    }
  }
`;

export const GET_SCREENS_REGISTERED = gql`
  query CantidadPantallasAdmin {
    CantidadPantallasAdmin {
      pantallas_contrato
      registradas
      no_registradas
      offline
      online
    }
  }
`;

//ZONAS HORARIAS
export const GET_TIMEZONES = gql`
  query GetZonasHorarias {
    GetZonasHorarias {
      zona_horaria
    }
  }
`;

export const GET_ESTADO_CANALES = gql`
  query EstadoCanal {
    EstadoCanales {
      id_estado
      id_estado_real
      nombre_estado
      color
    }
  }
`;

export const GET_ALL_WIDGETS = gql`
  query GetAllWidgets {
    GetAllWidgets {
      id_objeto
      tipo_widget
      imagen_widget
      nombre_widget
    }
  }
`;

export const GET_NOTICIAS_OPCIONES = gql`
  query FindNoticiasOpciones {
    FindNoticiasOpciones {
      id_rrss
      url_api
      nombre_rrss
      id_api_fk
    }
  }
`;

export const GET_ONE_CANAL = gql`
  query GetOneCanal($idCanal: Int!) {
    GetOneCanal(id_canal: $idCanal) {
      id_canal
      nombre_canal
      cantidad_pantallas
      estado {
        id_estado
        id_estado_real
        nombre_estado
        color
      }
      lista_reproduccion {
        url_logo
        id_lista
        codigo
        fecha_creacion
        color
        layout {
          id_layout
          nombre_layout
        }
        videos {
          id_file
          tiempo_ejecucion
          fecha_activacion
          total_tiempo
          orden
          video_tv {
            id_video
            titulo_video
            descripcion_video
            nombre_carpeta
            url_video
            url_imagen
            id_empresa_fk
            id_estado_fk
            alerta_push
            fecha_video
          }
          archivo {
            id_archivo
            nombre_archivo
            descripcion_archivo
            url_archivo
            url_imagen
            tiempo_video
            detalle_tipoa {
              id_detalle
              nombre_detalle
              tipo_archivo {
                id_tipo
                nombre_archivo
              }
            }
          }
        }
        banners {
          id_file
          tiempo_ejecucion
          fecha_activacion
          total_tiempo
          orden
          archivo {
            id_archivo
            nombre_archivo
            descripcion_archivo
            url_archivo
            tiempo_video
            detalle_tipoa {
              id_detalle
              nombre_detalle
              tipo_archivo {
                id_tipo
                nombre_archivo
              }
            }
          }
        }
        banners_horizontales {
          id_file
          tiempo_ejecucion
          fecha_activacion
          total_tiempo
          orden
          archivo {
            id_archivo
            nombre_archivo
            descripcion_archivo
            url_archivo
            tiempo_video
            detalle_tipoa {
              id_detalle
              nombre_detalle
              tipo_archivo {
                id_tipo
                nombre_archivo
              }
            }
          }
        }
        widgets {
          id_widget
          tipo_widget
          tiempo_ejecucion
          api {
            id_api
            nombre_api
            url_api
            url_imagen
            clima_api {
              id_geolocalizacion
              ubicacion: direccion
              latitud
              longitud
              url_formada
            }
            dias_accidente {
              id_accidente
              dias_accidente
              texto_inicial
            }
            noticias {
              id_noticia
              titulo
              fecha
            }
            noticia_url {
              id_rrss
              nombre_rrss
              url_api
            }
            indicadores_api {
              id_indicador
              nombre_indicador
              fecha_creacion
              valor
            }
          }
          modulo {
            id_modulo
            url_imagen
            nombre_modulo
          }
          birthdays {
            id_usuario
            primer_nombre
            apellido_paterno
            fecha_nacimiento
            imagen_perfil
            cargo {
              id_cargo
              nombre_cargo
            }
          }
          nuevo_ingreso {
            id_usuario
            primer_nombre
            apellido_paterno
            imagen_perfil
            cargo {
              id_cargo
              nombre_cargo
            }
          }
          movimientos {
            fecha_movimiento
            usuario {
              id_usuario
              primer_nombre
              apellido_paterno
              imagen_perfil
              fecha_ingreso
              cargo {
                id_cargo
                nombre_cargo
              }
            }
            cargo_nuevo {
              id_cargo
              nombre_cargo
            }
            cargo_antiguo {
              id_cargo
              nombre_cargo
            }
          }
          nuevos_padres {
            id_nacimiento
            nombre_bebe
            peso
            medida
            color
            fecha_nacimiento
            sexo {
              id_sexo
              nombre_sexo
            }
            usuario {
              id_usuario
              primer_nombre
              apellido_paterno
              imagen_perfil
              cargo {
                id_cargo
                nombre_cargo
              }
            }
          }
          reconocimientos {
            id_reconocimiento
            reconocidos {
              id_usuario
              primer_nombre
              apellido_paterno
              imagen_perfil
            }
            reconocedor {
              id_usuario
              primer_nombre
              apellido_paterno
              apellido_materno
              imagen_perfil
            }
            relacion {
              id_relacion
              nombre_relacion
            }
            valor_comportamiento {
              id_valor_comportamiento
              valor {
                id_valor
                nombre_valor
              }
              comportamiento {
                id_comportamiento
                nombre_comportamiento
              }
            }
          }
        }
        widgets_rrss {
          id_widget
          id_rrss
          nombre_rrss
          url_api
        }
      }
    }
  }
`;

export const GET_LOG_TVS_PAGINATION = gql`
  query PaginacionLogsCanalPantallas(
    $tipo: String!
    $limite: Int!
  ) {
  PaginacionLogsCanalPantallas(limite: $limite, tipo: $tipo) {
    pagina
    ids
  }
}
`;

export const GET_LOG_TVS = gql`
  query InformacionLogsPantallas(
    $ids: [Int]!
  ) {
  InformacionLogsPantallas(ids: $ids) {
    id_pantalla
    nombre_pantalla
    fecha_eliminacion
    id_eliminador
    ubicacion
    eliminador {
      id_usuario
      primer_nombre
      apellido_paterno
      rut_usuario
      dv_usuario
    }
  }
}
`;

export const GET_LOG_CHANELS = gql`
  query InformacionLogsCanales(
    $ids: [Int]!
  ) {
  InformacionLogsCanales(ids: $ids) {
    id_canal
    nombre_canal
    fecha_eliminacion
    id_eliminador
    eliminador {
      id_usuario
      primer_nombre
      apellido_paterno
      rut_usuario
      dv_usuario
    }
  }
}

`;

export const GET_PALETA_COLORES = gql`
  query GetPaletaColores {
    GetPaletaColores
  }
`;

export const GET_TOTAL_FILES_REPOSITORIO = gql`
  query TotalFilesRepositorio(
    $idEmpresa: String!
    $tipoArchivo: String!
    $limite: String!
    $nombreArchivo: String!
  ) {
    TotalFilesRepositorio(
      id_empresa: $idEmpresa
      tipo_archivo: $tipoArchivo
      limite: $limite
      nombre_archivo: $nombreArchivo
    ) {
      ids
      pagina
    }
  }
`;

export const GET_PAGINAS_FILES_REPOSITORIO = gql`
  query PaginasFilesRepositorio($idEmpresa: String!, $idArchivo: [String]!) {
    PaginasFilesRepositorio(id_empresa: $idEmpresa, id_archivo: $idArchivo) {
      id_archivo
      nombre_archivo
      descripcion_archivo
      id_estado_fk
      fecha_creacion
      fecha_modificacion
      tiempo_video
      url_imagen
      url_archivo
      detalle_tipoa {
        id_detalle
        nombre_detalle
        descripcion_detalle
        cantidad
        dimensiones_alto
        dimensiones_ancho
        peso
        id_estado_fk
        fecha_creacion
        tipo_archivo {
          id_tipo
          nombre_archivo
          descripcion_archivo
          id_estado_fk
        }
      }
    }
  }
`;

export const GET_TOTAL_PANTALLAS_ONOFF = gql`
  query TotalPantallasOnOff($id_estado_pantalla: Int!) {
    TotalPantallasOnOff(id_estado_pantalla: $id_estado_pantalla) {
      ids
      pagina
    }
  }
`;

export const GET_TOTAL_PANTALLAS = gql`
  query totalPantallas($idEmpresa: String!, $limite: String!) {
    TotalPantallas(id_empresa: $idEmpresa, limite: $limite) {
      pagina
      ids
    }
  }
`;

export const GET_PANTALLAS_PAGINADAS = gql`
  query PantallasPaginadas($idPantalla: [Int]!) {
    PantallasPaginadas(id_pantalla: $idPantalla) {
      id_pantalla
      fecha_conexion
      nombre_pantalla
      codigo
      ubicacion
      estado {
        id_estado
        nombre_estado
      }
      canal {
        nombre_canal
        id_canal
        cantidad_pantallas
        estado {
          id_estado
          nombre_estado
          color
        }
        frecuencia {
          id_frecuencia
        }
        lista_reproduccion {
          id_lista
          url_logo
          videos {
            id_file
            archivo {
              id_archivo
              url_archivo
              detalle_tipoa {
                id_detalle
                tipo_archivo {
                  id_tipo
                  nombre_archivo
                }
              }
            }
          }
          banners {
            id_file
            archivo {
              id_archivo
              url_archivo
              detalle_tipoa {
                id_detalle
                tipo_archivo {
                  id_tipo
                  nombre_archivo
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_TRAMOS_INFO = gql`
  query tramosConInfo($idEmpresa: String!, $idTramos: [String!]) {
    TramosConInfo(id_empresa: $idEmpresa, id_tramos: $idTramos) {
      id_tramo
      codigo
      codigo_asientos
      asientos {
        id_asiento
        nombre_asiento
        disponible
        solicitud {
          id_solicitud
          fecha_creacion
          usuario {
            id_usuario
            primer_nombre
            apellido_paterno
          }
        }
      }
      transporte_lista_destino {
        id_destino
        nombre_origen
        nombre_destino
        is_conexion
        ida_vuelta
        transporte_tipo_medio {
          id_medio
          nombre_medio
          cantidad_asiento
          transporte_tipo {
            id_tipo
            nombre_transporte
          }
        }
        transporte_lista_destino_padre {
          id_destino
          nombre_origen
          nombre_destino
          is_conexion
          ida_vuelta
        }
      }
      transporte_planificacion {
        id_planificacion
        nombre_planificacion
      }
      transporte_solicitud {
        id_solicitud
        fecha_creacion
        usuario {
          id_usuario
          primer_nombre
          segundo_nombre
          apellido_paterno
          apellido_materno
          rut_usuario
          dv_usuario
          correo
          telefono_movil
          telefono_fijo
          anexo
          imagen_perfil
          genero
          token
          fecha_nacimiento
          year
          fecha_ingreso
          empresa {
            id_empresa
            nombre_empresa
          }
          cargo {
            id_cargo
            nombre_cargo
          }
          sucursal {
            id_sucursal
            nombre_sucursal
          }
          gerencia {
            id_gerencia
            nombre_gerencia
            bussiness_unit
            unit_number
          }
          turno {
            id_turno
            nombre_turno
          }
          nacion {
            id_nacion
            nombre_nacion
          }
          sociedad {
            id_sociedad
            nombre_sociedad
          }
          sindicato {
            id_sindicato
            nombre_sindicato
          }
          team {
            id_team
            nombre_team
          }
          privilegio {
            id_tipo
            nombre_tipo
          }
          grupo {
            id_grupo
            nombre_grupo
            descripcion_grupo
          }
        }
        transporte_asiento {
          id_asiento
          nombre_asiento
          disponible
        }
        transporte_asiento_conexion {
          id_asiento
          nombre_asiento
          disponible
        }
        transporte_ida_vuelta {
          id_ida_vuelta
          nombre_idea_vuelta
        }
      }
      tranpsorte_planificacion_detalle {
        id_detalle
        concurrencia_ejecucion_semanal
        concurrencia_ejecucion_day
        concurrencia_ejecucion_month
        concurrencia_tiempo
        inicio_periodo_semanal
        inicio_periodo_day
        inicio_periodo_month
        hora_inicio_periodo
        final_periodo_semanal
        final_periodo_day
        final_periodo_month
        hora_final_periodo
        fecha_inicio_ejecucion
        fecha_final_ejecucion
      }
      fecha_viaje
      fecha_inicio_inscripcion
      fecha_fin_inscripcion
      hora_viaje
      tipo_tramo
      asientos_totales
      asientos_tomados
    }
  }
`;

export const GET_TRAMOS_PLANIFICADOS_PAGINACION = gql`
  query PaginacionTramosEnCurso(
    $idEmpresa: String!
    $tipoRango: String!
    $nombre: String!
    $limit: String!
    $fechaInicio: String!
    $fechaFin: String!
    $idPlanificacion: String!
  ) {
    PaginacionTramosEnCurso(
      id_empresa: $idEmpresa
      tipo_rango: $tipoRango
      nombre: $nombre
      limit: $limit
      fecha_inicio: $fechaInicio
      fecha_fin: $fechaFin
      id_planificacion: $idPlanificacion
    ) {
      pagina
      planificaciones
    }
  }
`;

export const GET_YEAR_DASHBOARD = gql`
  query YearsDashboard($id_empresa: String!) {
    YearsDashboard(id_empresa: $id_empresa) {
      year
    }
  }
`;

export const GET_DATA_EXCEL_ESTADISTICAS_TOTAL = gql`
  query MetricasExcel(
    $id_empresa: String!
    $fecha_inicio: String!
    $fecha_termino: String!
  ) {
    MetricasExcel(
      id_empresa: $id_empresa
      fecha_inicio: $fecha_inicio
      fecha_fin: $fecha_termino
    ) {
      login {
        id_usuario
        primer_nombre
        segundo_nombre
        apellido_paterno
        apellido_materno
        rut_usuario
        dv_usuario
        cargo {
          id_cargo
          nombre_cargo
        }
        sucursal {
          id_sucursal
          nombre_sucursal
        }
        gerencia {
          id_gerencia
          nombre_gerencia
        }
      }
      modulos {
        id_modulo
        nombre_modulo
        total_vistas
        total_likes
        total_comentarios
        aplica_likes
        aplica_comentarios
      }
      reconocimientos {
        id_reconocimiento
        fecha_creacion
        reconocidos {
          primer_nombre
          segundo_nombre
          apellido_paterno
          apellido_materno
          rut_usuario
          dv_usuario
          cargo {
            id_cargo
            nombre_cargo
          }
          sucursal {
            id_sucursal
            nombre_sucursal
          }
          gerencia {
            id_gerencia
            nombre_gerencia
          }
        }
        reconocedor {
          id_usuario
          primer_nombre
          segundo_nombre
          apellido_paterno
          apellido_materno
          rut_usuario
          dv_usuario
          cargo {
            id_cargo
            nombre_cargo
          }
          sucursal {
            id_sucursal
            nombre_sucursal
          }
          gerencia {
            id_gerencia
            nombre_gerencia
          }
        }
        relacion {
          id_relacion
          nombre_relacion
        }
        valor_comportamiento {
          valor {
            id_valor
            nombre_valor
          }
          comportamiento {
            id_comportamiento
            nombre_comportamiento
          }
        }
        comentario_reconocimiento
        tipo_reconocimiento {
          id_tipo
          nombre_tipo
        }
      }
      muros {
        id_muro
        imagen_muro
        fecha_creacion
        texto
        usuario {
          id_usuario
          primer_nombre
          segundo_nombre
          apellido_paterno
          apellido_materno
          rut_usuario
          dv_usuario
          cargo {
            id_cargo
            nombre_cargo
          }
          sucursal {
            id_sucursal
            nombre_sucursal
          }
          gerencia {
            id_gerencia
            nombre_gerencia
          }
        }
        likes {
          id_like
        }
        comentarios {
          id_comentario
          texto
          usuario {
            id_usuario
            primer_nombre
            segundo_nombre
            apellido_paterno
            apellido_materno
            rut_usuario
            dv_usuario
            cargo {
              id_cargo
              nombre_cargo
            }
            sucursal {
              id_sucursal
              nombre_sucursal
            }
            gerencia {
              id_gerencia
              nombre_gerencia
            }
          }
        }
      }
      noticias {
        likes_noticia {
          id_noticia
          likes_noticia
          titulo_noticia
        }
        visto_noticia {
          id_noticia
          titulo_noticia
          visto_noticia
        }
      }
      galerias {
        likes_galeria {
          id_galeria
          titulo_galeria
          likes_galeria
        }
        visto_galeria {
          id_galeria
          titulo_galeria
          visto_galeria
        }
      }
      videos {
        likes_video {
          id_video
          titulo_video
          likes_video
        }
        visto_video {
          id_video
          titulo_video
          visto_video
        }
      }
    }
  }
`;

//NACIMIENTOS / BIRTHS
export const GET_NACIMIENTOS_TOTAL = gql`
  query NuevosPadresPaginacion(
    $id_empresa: String!
    $fecha_inicio: String!
    $fecha_fin: String!
    $nombre: String!
    $limite: String!
  ) {
    NuevosPadresPaginacion(
      id_empresa: $id_empresa
      fecha_inicio: $fecha_inicio
      fecha_fin: $fecha_fin
      nombre: $nombre
      limite: $limite
    ) {
      pagina
      ids
    }
  }
`;

export const GET_NACIMIENTOS_PAGINADOS = gql`
  query listarNuevosPadresId($id_empresa: String!, $id_nacimiento: [String]!) {
    listarNuevosPadresId(
      id_empresa: $id_empresa
      id_nacimiento: $id_nacimiento
    ) {
      id_nacimiento
      nombre_bebe
      sexo {
        id_sexo
        nombre_sexo
      }
      img_nacimiento
      peso
      medida
      fecha_nacimiento
      usuario {
        id_usuario
        primer_nombre
        apellido_paterno
        imagen_perfil
        cargo {
          id_cargo
          nombre_cargo
        }
        gerencia {
          id_gerencia
          nombre_gerencia
        }
        sucursal {
          id_sucursal
          nombre_sucursal
        }
      }
    }
  }
`;

export const GET_GENEROS_OPTIONS = gql`
  query filtroGenero {
    filtroGenero {
      id_sexo
      nombre_sexo
    }
  }
`;

/* PERSONALIZACIÓN INTERFAZ */
export const LIST_MODULOS_EMPRESAS = gql`
  query listModulosEmpresas {
    listModulosEmpresas {
      id_detalle
      nombre_alias: nombre_modulo
      descripcion_modulo
      posicion_pantalla
      imagen_url
      url_menu_inactivo
      url_menu_activo
      ruta_web
      privilegio_modulos {
        id_modulo
        nombre_modulo
        descripcion_modulo
        imagen_url
      }
      id_estado_fk
      destacado
    }
  }
`;

export const BUSCAR_MENU = gql`
  query buscarMenu {
    buscarMenu {
      id_header
      nombre_header
      color_activo
      orden
      principal {
        id_elemento
        nombre_elemento
        orden
        url_externa
        secundarios {
          id_elemento
          nombre_elemento
          orden
          url_externa
          terciarios {
            id_elemento
            nombre_elemento
            orden
            url_externa
          }
        }
      }
    }
  }
`;

export const GET_INTERFAZ = gql`
  query getInterfaz {
    getInterfaz {
      color_primario
      logo_empresa
      isotipo_empresa
      logo_app_empresa
    }
  }
`;

export const GET_DASHBOARD_HOTELERIA = gql`
  query DashboardHoteleria(
    $idEmpresa: Int!
    $fechaIni: String!
    $fechaFin: String!
  ) {
    DashboardHoteleria(
      id_empresa: $idEmpresa
      fecha_ini: $fechaIni
      fecha_fin: $fechaFin
    ) {
      total_atendidas
      total_singestionar
      total_solicitudes
      total_estados {
        id_estado
        nombre_estado
        color
        total
        porcentaje
      }
      total_servicios {
        id_servicio
        nombre_servicio
        total
      }
    }
  }
`;

export const GET_SOLICITUDES_ADMIN_PAGINADO = gql`
  query getListaSolicitudesAdminPaginado(
    $nombreUsuario: String!
    $idEstado: Int!
    $pagina: Int!
    $idServicio: Int!
    $fechaIni: String!
    $fechaFin: String!
  ) {
    getListaSolicitudesAdminPaginado(
      nombre_usuario: $nombreUsuario
      id_estado: $idEstado
      pagina: $pagina
      id_servicio: $idServicio
      fecha_ini: $fechaIni
      fecha_fin: $fechaFin
    ) {
      previous_page
      next_page
      count
      total_pages
      data {
        id_solicitud
        codigo_solicitud
        descripcion_solicitud
        fecha_creacion
        usuario {
          id_usuario
          primer_nombre
          apellido_paterno
          imagen_perfil
          telefono_movil
          correo
          turno {
            nombre_turno
            id_turno
          }
          team {
            nombre_team
          }
        }
        habitacion {
          id_habitacion
          nombre_habitacion
          id_pabellon_fk {
            nombre_pabellon
          }
        }
        cama {
          id_cama
          nombre_ubicacion
        }
        closet {
          id_closet
          nombre_ubicacion
        }
        urgencia {
          id_urgencia
          nombre_urgencia
        }
        detalles {
          id_detalle
          descripcion_detalle
          detalle {
            nombre_detalle
            id_servicio_fk {
              id_servicio
              nombre_servicio
            }
          }
        }
        documentos {
          id_documento
        }
        mensajes {
          id_mensaje
          mensaje
          visto
          fecha_creacion
          administrador{
            primer_nombre
            apellido_paterno
            correo
          }
        }
        calificacion {
          id_calificacion_solicitud
        }
        estado {
          id_estado
          nombre_estado
          color
        }
        id_empresa_fk
      }
    }
  }
`;
export const GET_SOLICITUDES_ADMIN_EXCEL = gql`
  query SolicitudesHoteleriaExcel(
    $nombreUsuario: String!
    $idEstado: Int!
    $idServicio: Int!
    $fechaIni: String!
    $fechaFin: String!
  ) {
    solicitudesHoteleriaExcel(
      nombre_usuario: $nombreUsuario
      id_estado: $idEstado
      id_servicio: $idServicio
      fecha_ini: $fechaIni
      fecha_fin: $fechaFin
    ) {
      id_solicitud
      codigo_solicitud
      descripcion_solicitud
      fecha_creacion
      usuario {
        id_usuario
        primer_nombre
        apellido_paterno
        imagen_perfil
        telefono_movil
        correo
        rut_usuario
        dv_usuario
        turno {
          nombre_turno
          id_turno
        }
        team {
          nombre_team
        }
      }
      habitacion {
        id_habitacion
        nombre_habitacion
        id_pabellon_fk {
          nombre_pabellon
        }
      }
      cama {
        id_cama
        nombre_ubicacion
      }
      closet {
        id_closet
        nombre_ubicacion
      }
      urgencia {
        id_urgencia
        nombre_urgencia
      }      
      detalles {
        id_detalle
        detalle{
          nombre_detalle 
          is_descripcion
          id_estado_fk
          id_servicio_fk{
            id_servicio
            nombre_servicio 
          }
        }
      }
      documentos {
        id_documento
      }
      mensajes {
        id_mensaje
      }
      calificacion {
        id_calificacion_solicitud
      }
      estado {
        nombre_estado
      }
      id_empresa_fk
    }
  }
`;

export const GET_ESTADO_SOLICITUD_BY_EMPRESA = gql`
  query GetEstadoByIdEmp($idEmpresa: String!) {
    getEstadoByIdEmp(id_empresa: $idEmpresa) {
      id_estado
      nombre_estado
      __typename
      color
    }
  }
`;

export const GET_URGENCIA_SOLICITUD = gql`
  query getUrgenciaIdEmp($idEmpresa: String!) {
    getUrgenciaIdEmp(id_empresa: $idEmpresa) {
      id_urgencia
      nombre_urgencia
      id_estado_fk
      id_empresas_fk
    }
  }
`;

export const GET_CLOSET_UBICACION = gql`
  query getClosetIdEmp($idEmpresa: String!) {
    getClosetIdEmp(id_empresa: $idEmpresa) {
      id_closet
      nombre_ubicacion
      id_estado_fk
      id_empresa_fk
    }
  }
`;

export const GET_CAMA_UBICACION = gql`
  query getCamaIdEmp($idEmpresa: String!) {
    getCamaIdEmp(id_empresa: $idEmpresa) {
      id_cama
      nombre_ubicacion
      id_estado_fk
      id_empresa_fk
    }
  }
`;

export const GET_CALIFICACION_HOTELERIA = gql`
  query getCalificacionIdEmp($idEmpresa: String!) {
    getCalificacionIdEmp(id_empresa: $idEmpresa) {
      id_calificacion
      nombre_calificacion
      id_estado_fk
      id_empresa_fk
    }
  }
`;

export const GET_SERVICIOS_HOTELERIA = gql`
  query getServiciosIdEmp($idEmpresa: String!) {
    getServiciosIdEmp(id_empresa: $idEmpresa) {
      id_servicio
      nombre_servicio
      descripcion_servicio
      imagen_servicio
      detalle {
        id_detalle
        nombre_detalle
        is_descripcion
        id_estado_fk
        id_empresa_fk
      }
    }
  }
`;

export const GET_DETALLES_SERVICIOS_HOTELERIA = gql`
  query getServicioDetalleIdEmp($idEmpresa: String!, $idServicio: String!) {
    getServicioDetalleIdEmp(id_empresa: $idEmpresa, id_servicio: $idServicio) {
      id_detalle
      nombre_detalle
      is_descripcion
      id_servicio_fk {
        id_servicio
        nombre_servicio
        descripcion_servicio
        imagen_servicio
        id_empresa_fk
      }
      id_estado_fk
      id_empresa_fk
    }
  }
`;

export const GET_ALL_SOLICITUD_USUARIO = gql`
  query getAllSolicitudesUsuario($idEmpresa: String!, $idUsuario: String!) {
    getAllSolicitudesUsuario(id_empresa: $idEmpresa, id_usuario: $idUsuario) {
      id_solicitud
      codigo_solicitud
      descripcion_solicitud
      usuario {
        id_usuario
        primer_nombre
        segundo_nombre
        apellido_paterno
        apellido_materno
        rut_usuario
        dv_usuario
        correo
        telefono_movil
        telefono_fijo
        anexo
        imagen_perfil
        genero
        token
        fecha_nacimiento
        year
        fecha_ingreso
        entity_id
        id_empresa_fk
        id_sucursal_fk
        id_privilegio_fk
        id_estado_fk
      }
      habitacion {
        id_habitacion
        nombre_habitacion
        id_empresa_fk
        id_estado_fk
      }
      cama {
        id_cama
        nombre_ubicacion
        id_estado_fk
        id_empresa_fk
      }
      closet {
        id_closet
        nombre_ubicacion
        id_estado_fk
        id_empresa_fk
      }
      urgencia {
        id_urgencia
        nombre_urgencia
        id_estado_fk
        id_empresa_fk
      }
      detalles {
        id_detalle
        descripcion_detalle
      }
      documentos {
        id_documento
        url_documento
      }
      mensajes {
        id_mensaje
        mensaje
        visto
        fecha_creacion
      }
      calificacion {
        id_calificacion_solicitud
        fecha_creacion
      }
      fecha_creacion
      estado {
        id_estado
        nombre_estado
        color
        calificacion
        id_estado_fk
        id_empresa_fk
      }
      id_empresa_fk
    }
  }
`;

export const TOTAL_SOLICITUDES_USUARIO = gql`
  query totalSolicitudesUsuario($idEmpresa: String!, $idUsuario: String!) {
    totalSolicitudesUsuario(id_empresa: $idEmpresa, id_usuario: $idUsuario) {
      total
    }
  }
`;

export const LIMITE_ALL_SOLICITUDES_USUARIO = gql`
  query getAllSolicitudesUsuarioLimit(
    $idEmpresa: String!
    $idUsuario: String!
    $limit: String!
    $lastId: String!
  ) {
    getAllSolicitudesUsuarioLimit(
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
      limit: $limit
      last_id: $lastId
    ) {
      id_solicitud
      codigo_solicitud
      descripcion_solicitud
      usuario {
        id_usuario
        primer_nombre
        segundo_nombre
        apellido_paterno
        apellido_materno
        rut_usuario
        dv_usuario
        correo
        telefono_movil
        telefono_fijo
        anexo
        imagen_perfil
        genero
        token
        fecha_nacimiento
        year
        fecha_ingreso
        entity_id
        id_empresa_fk
        id_sucursal_fk
        id_privilegio_fk
        empresa {
          id_empresa
          nombre_empresa
        }
        cargo {
          id_cargo
          nombre_cargo
        }
        sucursal {
          id_sucursal
          nombre_sucursal
        }
        gerencia {
          id_gerencia
          nombre_gerencia
        }
        turno {
          id_turno
          nombre_turno
        }
        nacion {
          id_nacion
          nombre_nacion
        }
        sociedad {
          id_sociedad
          nombre_sociedad
        }
        sindicato {
          id_sindicato
          nombre_sindicato
        }
        team {
          id_team
          nombre_team
        }
        privilegio {
          id_tipo
          nombre_tipo
        }
        grupo {
          id_grupo
          nombre_grupo
        }
        id_estado_fk
      }
      habitacion {
        id_habitacion
        nombre_habitacion
        id_pabellon_fk {
          id_pabellon
          nombre_pabellon
        }
        id_empresa_fk
        id_estado_fk
      }
      cama {
        id_cama
        nombre_ubicacion
        id_estado_fk
        id_empresa_fk
      }
      closet {
        id_closet
        nombre_ubicacion
        id_estado_fk
        id_empresa_fk
      }
      urgencia {
        id_urgencia
        nombre_urgencia
        id_estado_fk
        id_empresa_fk
      }
      detalles {
        id_detalle
        descripcion_detalle
        detalle {
          id_detalle
          nombre_detalle
          is_descripcion
          id_servicio_fk {
            id_servicio
            nombre_servicio
            descripcion_servicio
            imagen_servicio
            detalle {
              id_detalle
              nombre_detalle
              is_descripcion
              id_servicio_fk {
                id_servicio
                nombre_servicio
              }
              id_estado_fk
              id_empresa_fk
            }
            id_empresa_fk
          }
          id_estado_fk
          id_empresa_fk
        }
      }
      documentos {
        id_documento
        url_documento
      }
      mensajes {
        id_mensaje
        mensaje
        visto
        fecha_creacion
        administrador {
          id_usuario
          id_sucursal_fk
        }
      }
      calificacion {
        id_calificacion_solicitud
        calificacion {
          id_calificacion
          nombre_calificacion
        }
        fecha_creacion
      }
      fecha_creacion
      estado {
        id_estado
        nombre_estado
        color
        calificacion
        id_estado_fk
        id_empresa_fk
      }
      id_empresa_fk
    }
  }
`;

export const GET_SOLICITUD_USUARIO_INDIVIDUAL = gql`
  query getOneSolicitudUsuario($idEmpresa: String!, $idSolicitud: String!) {
    getOneSolicitudUsuario(id_empresa: $idEmpresa, id_usuario: $idUsuario) {
      id_solicitud
      codigo_solicitud
      descripcion_solicitud
      usuario {
        id_usuario
        primer_nombre
        segundo_nombre
        apellido_paterno
        apellido_materno
        rut_usuario
        dv_usuario
        correo
        telefono_movil
        telefono_fijo
        anexo
        imagen_perfil
        genero
        token
        fecha_nacimiento
        year
        fecha_ingreso
        entity_id
        id_empresa_fk
        id_sucursal_fk
        id_privilegio_fk
        empresa {
          nombre_empresa
        }
        cargo {
          id_cargo
          nombre_cargo
        }
        sucursal {
          id_sucursal
          nombre_sucursal
        }
        gerencia {
          id_gerencia
          nombre_gerencia
        }
        turno {
          id_turno
          nombre_turno
        }
        nacion {
          id_nacion
          nombre_nacion
        }
        sociedad {
          id_sociedad
          nombre_sociedad
        }
        sindicato {
          id_sindicato
          nombre_sindicato
        }
        team {
          id_team
          nombre_team
        }
        privilegio {
          id_tipo
          nombre_tipo
        }
        grupo {
          id_grupo
          nombre_grupo
        }
        id_estado_fk
      }
      habitacion {
        id_habitacion
        nombre_habitacion
        id_pabellon_fk {
          id_pabellon
          nombre_pabellon
        }
        id_empresa_fk
        id_estado_fk
      }
      cama {
        id_cama
        nombre_ubicacion
        id_estado_fk
        id_empresa_fk
      }
      closet {
        id_closet
        nombre_ubicacion
        id_estado_fk
        id_empresa_fk
      }
      urgencia {
        id_urgencia
        nombre_urgencia
        id_estado_fk
        id_empresa_fk
      }
      detalles {
        id_detalle
        descripcion_detalle
        detalle {
          id_detalle
          nombre_detalle
        }
      }
      documentos {
        id_documento
        url_documento
      }
      mensajes {
        id_mensaje
        mensaje
        visto
        fecha_creacion
        administrador {
          id_usuario
        }
      }
      calificacion {
        id_calificacion_solicitud
        calificacion {
          id_calificacion
          nombre_calificacion
        }
        fecha_creacion
      }
      fecha_creacion
      estado {
        id_estado
        nombre_estado
        color
        calificacion
        id_estado_fk
        id_empresa_fk
      }
      id_empresa_fk
    }
  }
`;

export const GET_AMONESTACIONES_ID = gql`
  query getAmonestaciones($idAmonestacion: String!) {
    getAmonestaciones(id_amonestacion: $idAmonestacion) {
      id_amonestacion
      codigo_amonestacion
      descripcion_amonestacion
      id_usuario_fk {
        id_usuario
        primer_nombre
        segundo_nombre
        apellido_paterno
        apellido_materno
        rut_usuario
        dv_usuario
        correo
        telefono_movil
        telefono_fijo
        anexo
        imagen_perfil
        genero
        token
        fecha_nacimiento
        year
        fecha_ingreso
        entity_id
        id_empresa_fk
        id_sucursal_fk
        id_privilegio_fk
        id_estado_fk
      }
      imagen_amonestacion
      visto
      categoria {
        id_categoria
        nombre_categoria
        color
      }
      fecha_creacion
      id_estado_fk
      id_empresa_fk
    }
  }
`;
