<template>
  <div id="app">
    <main v-if='this.$ls.get("user") !== null' id="main" class="main"> 
      <SideBar/>
      <section class="wrapper-container">
        <NavBar :title="tituloModulo"/>
        <router-view/>
        <FooterSection/>
      </section>
    </main>
    <main v-else class="main-login">
      <router-view/>
    </main>
  </div>
</template>

<script>
  import "regenerator-runtime";
  import { mapActions, mapState, mapGetters } from "vuex";
  import SideBar from "@/components/SideBar.vue";
  import NavBar from "@/components/NavBar.vue";
  import FooterSection from "@/components/Section/SectionFooter.vue";
  import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css";
  import { EventBus } from '@/eventBus';

  export default {
    components: {
      SideBar,
      NavBar,
      FooterSection
    },
    data() {
      return {
        data: "",
        colorPrimario: this.$ls.get("user")?.empresa[0].color_primario,
        colorSecundario: this.$ls.get("user")?.empresa[0].color_secundario,
        idEmpresa: this.$ls.get("user")?.empresa[0].id_empresa,
        idPrivilegio: this.$ls.get("user")?.privilegio[0].id_tipo,
        idGrupo: this.$ls.get("user")?.grupo && typeof this.$ls.get("user")?.grupo[0]=== 'object' && this.$ls.get("user")?.grupo[0]!=null? this.$ls.get("user")?.grupo[0].id_grupo : "null",
        
        //FF
        currentRoute: "",
        previousRoute: "",
        moduleRoute: window.location.pathname,
        tituloModulo: "",
      };
    },
    watch: {
      moduleRoute() {
        this.getNombreModulo();
      }
    },
    
    computed: {
      ...mapState("sidebar", ["modules"]),
      ...mapGetters("sidebar", ["userHasOnlyOneModule"]),
    },

    async created() {
      /**
       * Pendo
       * https://www.pendo.io/
      */
      if (!window.pendo && process.env.VUE_APP_PENDO_KEY) {
        (function (p, e, n, d, o) {
          var v, w, x, y, z;
          o = p[d] = p[d] || {};
          o._q = [];
          v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
          for (w = 0, x = v.length; w < x; ++w)
            (function (m) {
              o[m] =
                o[m] ||
                function () {
                  o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
                };
            })(v[w]);
          y = e.createElement(n);
          y.async = !0;
          y.src = `https://cdn.pendo.io/agent/static/${process.env.VUE_APP_PENDO_KEY}/pendo.js`;
          z = e.getElementsByTagName(n)[0];
          z.parentNode.insertBefore(y, z);
        })(window, document, 'script', 'pendo');

        // Initialize Pendo with visitor and account data
        window.pendo.initialize({
          visitor: {
            id: this.$ls.get("user").id_usuario, // Required if no email is available
            email: this.$ls.get("user").correo, // Optional
            role: this.$ls.get("user").cargo[0].nombre_cargo,
            full_name: `${this.$ls.get("user").primer_nombre} ${this.$ls.get("user").apellido_paterno}`,
            company: this.$ls.get("user").empresa[0].nombre_empresa
          },
          account: {
            id: process.env.VUE_APP_PENDO_KEY, // Required
          },
        });
      }
      const data = {
        id_empresa: this.idEmpresa,
        id_privilegio: this.idPrivilegio,
        id_grupo: this.idGrupo
      };
      if(data.id_empresa!=undefined && data.id_privilegio!=undefined){
        await this.getModules(data);
        this.getNombreModulo();
      }

      EventBus.$on("logout", () => { this.logOut() });
      EventBus.$on("failedServerResponse", () => { this.toastServerError() });
    },

    beforeDestroy() {
      EventBus.$off("logout", () => { this.logOut() });
      EventBus.$on("failedServerResponse");
    },

    updated() {
      this.moduleRoute = window.location.pathname;
    },

    mounted() {
      this.getNombreModulo();
    },
    
    methods: {
      ...mapActions("sidebar", ["getModules"]),

      //FF
      getNombreModulo() {
        let modulo = "";
        let pathModulo = ""; 
        if(this.moduleRoute.includes('administrador')){
          modulo = this.moduleRoute.split('/', 4);
          pathModulo = "/" + modulo[2];
        } else {
          modulo = this.moduleRoute.split('/', 3);
          pathModulo = "/" + modulo[1];
        }
        
        // Módulos que no vienen de ws y que no se muestran en sidebar:
        // Mi Perfil y Personalización, dado que son transversales
        if (pathModulo === "/perfil") {
          this.tituloModulo = "Mi Perfil";
        } else if (pathModulo === "/customization") {
          this.tituloModulo = "Personalización";
        } else {   
          try {
            const found = this.modules.find(element => element.moduloGeneral.ruta_admin === pathModulo);
            if (found) {
              this.tituloModulo = found.nombre_modulo
            }
          } catch(error) {
            console.log("error getNombreModulo", error);
          }
        }
      }
    },
  };

</script>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
  input:focus-visible {
    outline: none;
  }
  input[type="text"] {
    font-size: 15px;
  }
  input[type="number"] {
    color: #6B6E7F;
  }
  ul {
    padding: 0;
    list-style: none;
    margin: 0;
  }

}
</style>
