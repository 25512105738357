/**
 * Convierte un número de segundos a un formato de tiempo hh:mm:ss.
 *
 * @param {number} seconds - El número total de segundos a convertir.
 * @returns {string} - El tiempo formateado en el formato hh:mm:ss.
 *
 * @example
 * // Devuelve '00:02:15'
 * formatTime(135);
 *
 * @example
 * // Devuelve '01:00:00'
 * formatTime(3600);
 *
 * @example
 * // Devuelve '12:34:56'
 * formatTime(45296);
 */
export function formatTime(seconds) {
  // Calcular horas, minutos y segundos
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  // Formatear cada componente para tener dos dígitos
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(secs).padStart(2, "0");

  // Combinar en formato hh:mm:ss
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

/**
 * Convierte un tiempo en formato hh:mm:ss a un número total de segundos.
 *
 * @param {string} time - El tiempo en formato hh:mm:ss.
 * @returns {number} - El número total de segundos.
 *
 * @example
 * // Devuelve 135
 * parseTime("00:02:15");
 *
 * @example
 * // Devuelve 3600
 * parseTime("01:00:00");
 *
 * @example
 * // Devuelve 45296
 * parseTime("12:34:56");
 */
export function parseTime(time) {
    // Separar las horas, minutos y segundos
    const [hours, minutes, seconds] = time.split(':').map(Number);
  
    // Convertir todo a segundos y sumar
    return (hours * 3600) + (minutes * 60) + seconds;
  }


 export function formatTimeInt(intTime) {
  const paddedTime = `${intTime}`.padStart(6, "0");
  const hours = paddedTime.slice(0, 2);
  const minutes = paddedTime.slice(2, 4);
  const seconds = paddedTime.slice(4, 6);
  return `${hours}:${minutes}:${seconds}`;
 }