<template>
  <div class="table-container">
    <Loading v-if="loading" pixeles="150px"/>
    <template v-if="!loading">  
      <table 
        v-if="!loading && canales.length !== 0"
        class="table table-custom table-borderless table-custom-responsive mb-0 canales">
        <thead>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col" class="text-center">N° Pantallas</th>
            <th scope="col" class="text-center">Estado</th>
            <th scope="col" class="text-center">Última modificación</th>
            <th scope="col" class="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody v-show="canales.length != 0">
          <tr v-for="(c, i) in canales" :key="i">
            <td class="th th-nombre" :class="[c.estado.nombre_estado === 'Eliminado' ? 'eliminado' : '']">
              {{ c.nombre_canal }}
            </td>
            <td class="th th-numero-pantallas text-center">
              {{ c.cantidad_pantallas }}
            </td>
            <td class="th th-estado text-center">
              <span v-if="c.estado.nombre_estado === 'Activo'"
                class="badge badge-custom d-block activo">
                {{ c.estado.nombre_estado }}
              </span>
              <span v-if="c.estado.nombre_estado === 'Inactivo'"
                class="badge badge-custom d-block inactivo">
                {{ c.estado.nombre_estado }}
              </span>
              <span v-if="c.estado.nombre_estado === 'Sin contenido'"
                class="badge badge-custom d-block sin-contenido">
                {{ c.estado.nombre_estado }}
              </span>
              <span v-if="c.estado.nombre_estado === 'Eliminado'"
                class="badge badge-custom d-block eliminado">
                {{ c.estado.nombre_estado }}
              </span>
            </td>
            <td class="th th-ultima-modificacion text-center">
              {{ dateFormatymdhours(c.fecha_modificacion) }}
            </td>
            <td class="th th-accion text-center" v-if="c.estado.nombre_estado !== 'Eliminado'">
              <div class="dropdown dropdown-custom d-flex justify-content-center">
                  <button 
                    @click="openOption(c.id_canal, c)"
                    class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                    type="button"
                    data-bs-toggle="dropdown">
                    <font-awesome-icon icon="ellipsis-vertical"/>
                  </button>
                  <ul 
                    class="dropdown-menu"
                    v-click-outside="outOption">
                    <li v-show="c.estado.nombre_estado != 'Sin contenido'">
                      <a 
                        @click="editChannelContent(c.id_canal)"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-main" icon="pen"/>
                        Editar contenido
                      </a>
                    </li>
                    <li v-show="c.estado.nombre_estado === 'Sin contenido'">
                      <a 
                        @click="addContent(c.id_canal)"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-main" icon="upload"/>
                        Cargar contenido
                      </a>
                    </li>
                    <!-- <li v-show="c.estado.nombre_estado === 'Activo'">
                      <a 
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-main" icon="pause"/>
                        Pausar transmisión
                      </a>
                    </li> -->
                    <li v-show="c.estado.nombre_estado === 'En pausa'">
                      <a 
                        @click="playRequest"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-main" icon="play"/>
                        Reproducir
                      </a>
                    </li>
                    <li>
                      <a 
                        @click="editChannelConfig(c.id_canal)"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-secondary" icon="gear"/>
                        Configurar
                      </a>
                    </li>
                    <li>
                      <a 
                        @click="deleteChannel(c.id_canal)"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-main" icon="trash-alt"/>
                        Eliminar
                      </a>
                    </li>
                  </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>

    <SinResultados v-if="!loading && canales.length === 0"/>

  </div>
</template>
 
 <script>
  //FF
  import Loading from "@/components/Loading.vue";
  import SinResultados from "@/components/SinResultados.vue";

export default {
  components: { 
    //FF
    Loading,
    SinResultados
  },
  props: {
    canales: {
      type: Array,
    },
    loading: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      option: "-1",
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      id_usuario : this.$ls.get("user").id_usuario
    };
  },
  methods: {
    openOption(i) {
      if (this.option == i) {
        this.option = -1;
      } else {
        setTimeout(() => {
          this.option = i;
        }, 10);
      }
    },
    outOption() {
      if (this.option != -1) {
        this.option = -1;
      }
    },
    deleteChannel(id) {
      this.$emit("deleteChannel", id);
    },
    playRequest() {
      this.$emit("playRequest");
    },
    editChannelContent(id_) {
      this.$router.push({
        name: "CanalCorporativo-editContenido",
        params: {
          id: id_,
        },
      });
    },
    editChannelConfig(id_) {
      this.$router.push({
        name: "CanalCorporativo-edit-canales",
        params: {
          id: `${id_}`,
        },
      });
    },
    addContent(id_) {
      this.$router.push({
        name: "CanalCorporativo-addContenido",
        params: {
          id: id_,
        },
      });
    },
    duplicateChannel() {
      this.$emit("duplicateChannel");
    },
  },
};
</script>
 

<style lang="scss" scoped>
.pantallas-table__container {
  height: 100%;
}
</style>
