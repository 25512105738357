import Seguridad from '@/views/ADMIN/Seguridad.vue'
import SeguridadList from '@/components/Seguridad/List.vue'
import SeguridadAdd from '@/components/Seguridad/Add.vue'
import SeguridadAddCat from '@/components/Seguridad/AddCategories.vue'

export const seguridadList = {
  path: "/seguridad",
  name: "Seguridad",
  meta: { requiresAuth: true},
  component: Seguridad,
  redirect: { name: "seguridad-list" },
  children: [ {
      path: "list",
      name: "seguridad-list",
      component: SeguridadList
    }, {
      path: "add",
      name: "seguridad-add",
      component: SeguridadAdd
    }, {
      path: "edit/:id",
      name: "seguridad-edit",
      component: SeguridadAdd
    }, {
      path: "add-categories",
      name: "seguridad-add-categories",
      component: SeguridadAddCat
    },
  ],
};