import Benefit from '@/views/ADMIN/Benefit.vue'
import BenefitDashboard from '@/components/Benefit/Dashboard.vue'
import BenefitRequest from '@/components/Benefit/BenefitRequest.vue'

export const benefitList = {
  path: "/beneficios",
  name: "Beneficios",
  meta: { requiresAuth: true},
  component: Benefit,
  redirect: { name: "benefit-Dashboard" },
  children: [ {
      path: "Dashboard",
      name: "benefit-Dashboard",
      component: BenefitDashboard
    }, {
      path: "solicitud/:id",
      name: "solici-request",
      component: BenefitRequest
    },
  ],
};
