<template>
	<section class="add-banner__modal-container">
		<div class="add-banner__upload-wrapper">
			<div class="add-logo__top-container">
				<font-awesome-icon @click="closeModal" class="add-logo__close-icon" icon="times"></font-awesome-icon>
				<div style="text-align: center;">
					<p class="add-logo__title">
						Formato PNG o JPEG - Dimensiones {{ dimensionsForLayout.height + 'x' + dimensionsForLayout.width }}px - Tamaño máximo 5MB
					</p>
				</div>
			</div>

			<div class="add-banner__middle-container">
				<UploadImg 
					v-if="img_preview_src == ''" 
					@sendImg="handleSelectImg" 
					:maxSizeMB="5"
					:viewport="viewportForThisLayout" 
					:dimensions="dimensionsForLayout"
					style="margin:auto;"
					:style="previewDimensions"
					ref="uploadImgRef"
					/>
				<div class="img-preview" v-if="img_preview_src !== ''">
					<div class="delete-preview" @click="deletePreview()">
						<button>
							<font-awesome-icon icon="times"></font-awesome-icon>
						</button>
					</div>
					<div class="img-preview-container">
						<img :src="img_preview_src" alt="" />
					</div>
				</div>

				<div class="d-flex justify-content-center">
					<p class="add-banner__legend-text text-center mb-0">Vista previa</p>
				</div>
			</div>

			<div class="add-logo__buttons-container">
				<button @click="closeModal" class="add-logo__button-cancel">Cancelar</button>
				<button @click="loadBanner" class="add-logo__button-add">Guardar</button>
			</div>
		</div>
	</section>
</template>
    
<script>
import { mapState, mapActions } from "vuex";
import UploadImg from "../UploadImg.vue";

export default {
	components: { UploadImg },
	data() {
		return {
      forBannerH: this.$route.name === "CanalCorporativo-listadoBannersHorizontal",
			id_empresa: this.$ls.get("user").empresa[0].id_empresa,
			id_usuario: this.$ls.get("user").id_usuario,
			img_preview_src: "",
			newImage: {}
		};
	},

	computed: {
		...mapState("tvCorporativaModule", ["channelLayout"]),

		dimensionsForLayout() {
			return { height: 1080, width: 1920 }
		},

		viewportForThisLayout() {
			return { height: 270, width: 480 }
		},

		previewDimensions() {
				return { height: '270px', width: '480px' };
		}
	},

	methods: {
		...mapActions("tvCorporativaModule", ["uploadFileSubscription"]),

		async loadBanner() {
			const data = {
				id_empresa: this.id_empresa,
				id_usuario: this.id_usuario,
				tipo_archivo: 6,
				file: this.newImage
			};

			this.$emit("showSpinner");

			try {				
				const fileObservable = await this.uploadFileSubscription(data);
				const subscription = fileObservable.subscribe(data => {
					const uploadProgressData = data.data.saveFileRepo;
					console.log("Sub ---- data.data");
					console.log(data.data);
					
					if (uploadProgressData.percent === '100' || uploadProgressData.url_archivo!==null) {
						setTimeout(() => {
							subscription.unsubscribe();
							this.toastImageCargaExitosa();
							this.$emit("hideSpinner");
							this.$emit("imageUploaded");
							this.closeModal();
						}, 1500);
					}
				});
			} catch (error) {
				this.toastImagenFallaCarga();
				this.$emit("hideSpinner");
				this.closeModal();
			}
		},

		handleSelectImg(img) {
			console.log({ img });
			
			this.newImage = {
				width: img.width,
				height: img.height,
				src: img.src,
				type: "image/" + img.format,
				name: img.name.split(".")[0],
				duration: 0,
			};
		},

		closeModal() {
			this.img_preview_src= "";
			this.newImage = {};
			this.$refs.uploadImgRef.resetImage();
			this.$emit("closeModal");
		},

		deletePreview() {
			this.newImage = {
				width: "",
				height: "",
				src: "",
				type: "",
				name: "",
			};
		},

		toastImagenFallaCarga() {
			this.$toast.open({
				message: "Falla al momento de cargar imagen.",
				type: "error",
				duration: 6000,
				position: "top-right",
			});
		},
		toastImageCargaExitosa() {
			this.$toast.open({
				message: "La imagen ha sido cargado exitosamente.",
				type: "success",
				duration: 6000,
				position: "top-right",
			});
		},
		toastImagenFallaFormato() {
			this.$toast.open({
				message: "Por favor selecciona un archivo con formato válido (.png, .jpg).",
				type: "warning",
				duration: 6000,
				position: "top-right",
			});
		}
	},
};
</script>