<template>
<div 
  class="card border-0 border-round-10 card-shadow carousel-custom-item"
  :class="{ 
    'type-bannerV': isItemBannerV,
    'type-bannerH': isItemBannerH,
    'type-video':   isItemVideo,
  }"
>
  <div class="card-body h-100">
    <VueVideoThumbnail 
      v-if="isItemVideo && isVideo(item?.url_archivo || item?.url_video)" 
      :video-src="item?.url_video || item?.url_archivo ||  dataURL"
      :width="170"
      :height="100"
      :snapshot-at-duration-percent="1"
      :chunks-quantity="1"
      class="img-fluid img-obj card-item-add-img rounded"
    >
    </VueVideoThumbnail>
    <img v-else 
      class="border-round-10 carousel-custom-item-img" 
      :src="dataURL || item[thumbnailField]" 
    />
    <div 
      v-if="removable"
      class="carousel-custom-item-remove"
      :class="{ 'remove-thumbnail-image': !isItemVideo }"
    >
      <span v-if="isItemVideo && isVideo(item?.url_archivo || item?.url_video)" class="carousel-custom-item-remove-time">
        {{ item?.tiempo_video }}
      </span>
      <span v-else class="carousel-custom-item-remove-time">{{ time }}</span>
      <a 
        @click="deleteItem(index)"
        href="javascript:"
        class="carousel-custom-item-remove-icon"
      >
        <font-awesome-icon icon="times"/>
      </a>
    </div>
    <div v-if="isItemVideo" class="carousel-custom-item-title">
      {{ item?.titulo_video | capitalize }}
    </div>
  </div>
</div>

</template>


<script>
//card border-0 border-round-10 card-shadow carousel-custom-item type-bannerV
import VueVideoThumbnail from 'vue-video-thumbnail';
import {videoExtensions} from '@/utils/constants.js'
import {formatTime} from "@/utils/time.js";
import { mapState } from "vuex";

export default {
  components: { VueVideoThumbnail },
  props: {
    item: Object,
    thumbnailField: String,
    removable: { type: Boolean, default: true },
    index: { type: Number, default: 0 },
    dataURL: String
  },
  
  data() { return { }; },

  computed: {
    ...mapState("tvCorporativaModule", ["imagesListTime"]),
    isItemBannerV() {
      return this.$route.name === "CanalCorporativo-listadoBanners" ||
        this.item?.detalle_tipoa?.nombre_detalle === "Banners";
    },
    isItemBannerH() {
      return this.$route.name === "CanalCorporativo-listadoBannersHorizontal" ||
        this.item?.detalle_tipoa?.nombre_detalle === "Banners Horizontales";
    },
    isItemVideo() {
      return this.$route.name === "CanalCorporativo-listadoVideos" || 
        this.item?.detalle_tipoa?.tipo_archivo?.nombre_archivo === "Videos" ||
        this.item?.titulo_video ||
        this.dataURLObj.media === "video";
    },
    dataURLObj() {
      console.log("this.dataURL", this.dataURL);
      
      const media  = this.dataURL.split(/:|;|,|\//)[1];
      const base64 = this.dataURL.split(/:|;|,|\//)[4];
      return { media, base64 };
    },

    time(){
      return formatTime(this.imagesListTime)
    }
  },

  methods: {
    isVideo(arUrl){
      const extension = arUrl.split('.').pop();
      return videoExtensions.has(extension);
    },
    deleteItem(index) {
      this.$emit("deleteItem", index);
    }
  },

  filters: {
    capitalize: function (value) {
      if (!value) return '';
      value = value.toString().toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/_variables.scss";

.carousel-custom-item {
  position: relative;
  flex: 1;
  min-width: 150px;
  max-width: 150px;
  margin: 0 5px;
  &-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &-remove {
    position: absolute;
    display: flex;
    width: calc(100% - 30px);
    justify-content: space-between;
    align-items: center;
    top: 5px;
    right: 15px;
    &-time {
      display: flex;
      justify-content: center;
      border: 1px solid lighten($blue-color, 30%);
      align-items: center;
      color: $blue-color;
      background-color: rgba($white, 0.9);
      border-radius: 5px;
      height: 25px;
      padding: 0 5px;
      font-size: 14px;
      margin: auto 0 0 0;

    }
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0;
      margin-bottom: auto;
      margin-right: -5px;
      color: $white;
      background-color: $blue-color;
      height: 30px;
      width: 30px;
      border-radius: 50px;
      &:hover {
        color: $white;
      }
    }
  }
  &-title {
    position: absolute;
    width: 150px;
    left: 25px;
    bottom: 25px;
    background-color: rgba($white, 0.9);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 5px;
    border-radius: 5px;
    font-size: 14px;
    color: lighten($black, 30%);
    border: 1px solid lighten($blue-color, 30%);
  }
}

.type-bannerV {
  min-width: 150px;
  max-width: 150px;
  height: 200px;
}

.type-bannerH {
  min-width: 150px;
  max-width: 150px;
  height: 70px;
}

.type-video {
  min-width: 200px;
  max-width: 200px;
}
.remove-thumbnail-image {
  width: 100%;
  top: 0px;
  bottom: 0px;
  right: 0px;
}
</style>