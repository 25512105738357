import Culture from '@/views/ADMIN/Culture.vue'
import CultureAdd from '@/components/Culture/Add.vue'
import CultureValorAdd from '@/components/Culture/AddValor.vue'
import CultureList from '@/components/Culture/List.vue'

export const cultureList = {
    path: "/nuestra-cultura",
    name: "Cultura",
    meta: { requiresAuth: true},
    component: Culture,
    redirect: { name: "culture-list" },
    children: [{
            path: "add",
            name: "culture-add",
            component: CultureAdd
        },
        {
            path: "addvalor/",
            name: "culture-valor-add",
            component: CultureValorAdd
        },
        {
            path: "editvalor/:id",
            name: "culture-valor-edit",
            component: CultureValorAdd
        },
        {
            path: "list",
            name: "culture-list",
            component: CultureList
        },
    ],
};