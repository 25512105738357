<template>
  <div class="parent">
    <!-- Video -->
    <div class="div1"> 
      <div class="logo-container">
        <a 
          @click="addLogo()"
          href="javascript:" 
          data-bs-toggle="modal" 
          data-bs-target="#modal-layout-logo"
          class="logo-box"
        >
          <img v-if="contentPreview.logo" :src="contentPreview.logo" class="logo-box-img">
          <p v-else class="mb-0 py-2 px-3" > </p>
        </a>
        <AddEditLayoutButton 
        href="javascript:" 
        data-bs-toggle="modal" 
        data-bs-target="#modal-layout-logo"
        @click="addLogo()" 
          v-if="contentPreview.logo" edit media="logo"/>
        <AddEditLayoutButton @click="addLogo()" 
          href="javascript:" 
          data-bs-toggle="modal" 
          data-bs-target="#modal-layout-logo" 
          v-else add media="logo"/>
      </div>

      <a 
        @click="addVideo"
        href="javascript:"
        class="thumbnail-container"
      >
        
        <template v-if="isVideo(contentPreview.videoImg)">
          <!-- Renderiza el thumbnail del video -->
          <VueVideoThumbnail 
            class="video-box"
            :video-src="contentPreview.videoImg"
            :snapshot-at-duration-percent="1"
            :chunks-quantity="1"
            snapshotScaleType="cover"
            :width="700"
            :height="500"
          />
        </template>
        
        <template v-else-if="isImage(contentPreview.videoImg)">
          <!-- Renderiza la imagen si no es un video -->
          <img :src="contentPreview.videoImg" class="image-box" />
        </template>
        <p v-else class="thumbnail-container-text mb-0">
          <font-awesome-icon :icon="['fas', 'play-circle']" size="6x" class="add-media-icon" />
        </p>
        <span class="thumbnail-container-video-btn">
          <AddEditLayoutButton v-if="contentPreview.videoImg" edit media="video"/>
          <AddEditLayoutButton v-else add media="video"/>
        </span>
        <span v-if="contentPreview.videoImg" class="thumbnail-container-pagination" @click.stop="()=>{}">
          <PaginationLayoutButton
            :currentPage.sync="contentPreview.activeVideoPage" 
            :totalPages="contentPreview.numVideos"
            @arrowClick="changeDisplayedVideo"
          />
        </span>
      </a>
    </div>
    <!-- Banner -->
    <div class="div2">
      <a 
        @click="addBanner" 
        href="javascript:" 
        class="thumbnail-container"
      >
        <img v-if="contentPreview.bannerImg" 
          class="banner-box"
          :src="contentPreview.bannerImg" 
        />
        <p v-else class="thumbnail-container-text mb-0">
          <font-awesome-icon :icon="['fas', 'image']" size="6x" class="add-media-icon" />
        </p>
        <span class="thumbnail-container-banner-btn">
          <AddEditLayoutButton v-if="contentPreview.bannerImg" edit media="banner"/>
          <AddEditLayoutButton v-else add media="banner"/>
        </span>
        <span v-if="contentPreview.bannerImg" class="thumbnail-container-pagination" @click.stop="()=>{}">
          <PaginationLayoutButton
            :currentPage.sync="contentPreview.activeBannerPage" 
            :totalPages="contentPreview.numBanners"
            @arrowClick="changeDisplayedBanner"
          />
        </span>
      </a>
    </div>
    <!-- Hora -->
    <div class="div4">
      <a href="javascript:" class="hour-container">{{ date }}</a>
    </div>
    <!-- Widgets -->
    <div class="div5">
      <WidgetLayoutComponent :index="0" :widget="contentPreview.widgets[0]" :color="contentPreview.color" />
    </div>
    <div class="div6">
      <WidgetLayoutComponent :index="1" :widget="contentPreview.widgets[1]" :color="contentPreview.color" />
    </div>
    <div class="div7">
      <WidgetLayoutComponent :index="2" :widget="contentPreview.widgets[2]" :color="contentPreview.color" />
    </div>
    <div class="div8">
      <WidgetLayoutComponent :index="3" :widget="contentPreview.widgets[3]" :color="contentPreview.color" />
    </div>
  </div> 
</template>

<script>
import { isImage, isVideo} from "@/utils/formats.js";
import VueVideoThumbnail from 'vue-video-thumbnail'
import WidgetLayoutComponent from './WidgetLayoutComponent.vue';
import AddEditLayoutButton from "./AddEditLayoutButton.vue";
import PaginationLayoutButton from "./PaginationLayoutButton.vue";

export default {
  components: {
    VueVideoThumbnail,
    WidgetLayoutComponent,
    AddEditLayoutButton,
    PaginationLayoutButton
  },

  props: {
    contentPreview: Object
  },
  inject: [
    'addLogo', 'addVideo', 'addBanner', 'addWidget',
    'changeDisplayedVideo', 'changeDisplayedBanner'
  ],
  provide() {
    return {
      addWidget: this.addWidget
    }
  },

  computed: {
    date() {
      return this.nowChileMoment().format("HH:mm:ss")
    }
  },
  methods:{
    isVideo(url) {
      return isVideo(url)
    },
    isImage(url) {
      return isImage(url)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/_variables.scss";
.image-box{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.add-media-icon {
  color: $gray-2;
}
.logo-container {
  position: absolute;
  display: flex;
  gap: 6px;
  align-items: center;
  top: 20px;
  left: 20px;
  z-index: 1;
}
.logo-box {
  display: flex;
  width: 60px;
  height: 60px;
  border: 1px solid $border-color;
  background-color: lighten(black, 92%);
  &:hover {
    background-color: lighten($blue-color, 45%);
  }
}
.thumbnail-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  text-decoration: none;
  color: gray;
  &-text {
    text-align: center;
  }
  &.video {
    &:hover {
      background-color: lighten($blue-color, 45%);
    } 
  }
  &-video-btn {
    position: absolute;
    top: 12px;
    right: 12px;
  }
  &-banner-btn {
    position: absolute;
    top: 12px;
  }
  &-pagination {
    position: absolute;
    bottom: 12px;
  }
}

.hour-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: lighten($black, 20%);
  text-decoration: none;
}

.video-box {
  width: 100%;
  height: 100%;
}
.banner-box {
  width: 100%;
  height: 100%;
}

$base-unit: 46px; // Base unit remains the same

.parent {
  height: max-content;
  width: max-content;
  display: grid;
  grid-template-columns: repeat(4, 4.4*$base-unit); // Columns based on the new aspect ratio unit for div2 and div5-8
  grid-template-rows: 6.6*$base-unit 0.6*$base-unit 2.8*$base-unit; // Row heights adjusted to new aspect ratios, removing div3's row
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  box-sizing: border-box; // Include border in the element's size
}

/* Apply common styles to all divs */
.div1, .div2, .div4, .div5, .div6, .div7, .div8 {
  width: 100%; // Fill the container
  height: 100%;
  overflow: hidden;
  box-sizing: border-box; // Include border in the div's size
  background-color: $background-color-unset;
  &:hover {
    background-color: lighten($blue-color, 45%);
  }
}

.div1 { grid-area: 1 / 1 / 3 / 4; position: relative; }
.div2 { grid-area: 1 / 4 / 2 / 5; }
.div4 { grid-area: 2 / 4 / 3 / 5; }
.div5 { grid-area: 3 / 1 / 4 / 2; }
.div6 { grid-area: 3 / 2 / 4 / 3; }
.div7 { grid-area: 3 / 3 / 4 / 4; }
.div8 { grid-area: 3 / 4 / 4 / 5; }

/* BORDERS */
.div1, .div2, .div4, .div5, .div6, .div7, .div8 {
  border-right: 1px solid $border-color; /* Right border for all */
  border-bottom: 1px solid $border-color; /* Bottom border for all */
}
.div1, .div5 {
  border-left: 1px solid $border-color; /* Left border for first column items */
}
.div1, .div2 {
  border-top: 1px solid $border-color; /* Top border for first row items */
}
.div2, .div4, .div8 {
  border-right: 1px solid $border-color; /* Ensure right border for the last column */
}

</style>