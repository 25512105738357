import Customization from '@/views/ADMIN/customization/Customization.vue'
import CustomizationColors from '@/views/ADMIN/customization/ColorsView.vue'
import CustomizationModules from '@/views/ADMIN/customization/ModulesView.vue'
import CustomizationMenu from '@/views/ADMIN/customization/MenuView.vue'

export const customization = {
    path: "/customization",
    name: "Personalización",
    meta: { requiresAuth: true},
	component: Customization,
    redirect: { name: 'Logotipos y colores' },
    children: [ {
            path: 'colors',
            name: 'Logotipos y colores',
            component: CustomizationColors
        }, {
            path: 'modules',
            name: 'Gestión de módulos',
            component: CustomizationModules
        }, {
            path: 'menu',
            name: 'Menú personalizado',
            component: CustomizationMenu
        }
    ],
};