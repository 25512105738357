import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'bootstrap/dist/js/bootstrap.bundle.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import "./assets/scss/main.scss";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faStar as farStar,
    faHeart as farHeart,
    faTrashAlt as farTrashAlt

} from "@fortawesome/free-regular-svg-icons";
import {
    faPlay,
    faPlayCircle,
    faChevronRight,
    faChevronLeft,
    faBold,
    faItalic,
    faSortAlphaDown,
    faSortAlphaUp,
    faUnderline,
    faList,
    faListOl,
    faQuestion,
    faArrowDown,
    faCaretDown,
    faSort,
    faCaretUp,
    faImages,
    faImage,
    faKey,
    faLink,
    faTimes,
    faGripVertical,
    faThumbtack,
    faAngleLeft,
    faAngleRight,
    faStar,
    faCheck,
    faBan,
    faUpload,
    faSearch,
    faPen,
    faTrashAlt,
    faEye,
    faAngleDown,
    faAngleUp,
    faExclamationCircle,
    faPlusCircle,
    faCheckCircle,
    faDownload,
    faPlus,
    faExclamation,
    faUser,
    faExpand,
    faCommentDots,
    faBell,
    faPaperPlane,
    faEllipsisV,
    faComment,
    faBars,
    faCalendarDay,
    faHeart,
    faFileAlt,
    faChevronCircleLeft,
    faChevronCircleRight
} from "@fortawesome/free-solid-svg-icons";

// import { faHeart } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import VueAxios from "vue-axios";
import Storage from "vue-ls";
import { apolloProvider } from "./vue-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import VCalendar from "v-calendar";
import VueCroppie from "vue-croppie";
import VueTippy, { TippyComponent } from "vue-tippy";
import "croppie/croppie.css";
import VueApexCharts from "vue-apexcharts";
import VueProgressBar from "vue-progressbar";
import VueMask from "v-mask";
import VueSplide from "@splidejs/vue-splide";
import Paginate from 'vuejs-paginate';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import ProgressBar from 'vuejs-progress-bar'
import VueFormulate from '@braid/vue-formulate'
import { es } from '@braid/vue-formulate-i18n'
import ChileanRutify from 'chilean-rutify';

Vue.component('v-select', vSelect)
Vue.use(VueToast);
Vue.component('paginate', Paginate)
const options = {
    namespace: "vuejs__", // key prefix
    name: "ls", // name variable Vue.[ls] or this.[$ls],
    storage: "local", // storage name session, local, memory
};
const options_vue_progressBar = {
    color: "#bffaf3",
    failedColor: "#874b4b",
    thickness: "5px",
    transition: {
        speed: "0.2s",
        opacity: "0.6s",
        termination: 300,
    },
    autoRevert: true,
    location: "left",
    inverse: false,
};

library.add(
    faPlay,
    faPlayCircle,
    faChevronRight,
    faChevronLeft,
    faBold,
    faItalic,
    faSortAlphaDown,
    faSortAlphaUp,
    faUnderline,
    faList,
    faListOl,
    faQuestion,
    faArrowDown,
    faCaretDown,
    faSort,
    faCaretUp,
    faImages,
    faImage,
    faKey,
    faLink,
    faTimes,
    faGripVertical,
    faThumbtack,
    faHeart,
    faAngleLeft,
    faAngleRight,
    faStar,
    faCalendarDay,
    faCheck,
    faBan,
    faUpload,
    faSearch,
    faPen,
    faTrashAlt,
    faEye,
    faAngleDown,
    faAngleUp,
    faExclamationCircle,
    faPlusCircle,
    faCheckCircle,
    faDownload,
    faPlus,
    faExclamation,
    faUser,
    faExpand,
    faCommentDots,
    faBell,
    faPaperPlane,
    faEllipsisV,
    faComment,
    faBars,
    farStar,
    faFileAlt,
    farHeart,
    farTrashAlt,
    faChevronCircleLeft,
    faChevronCircleRight
);

Vue.use(VueMask);
Vue.use(VueAxios, axios);
Vue.use(Storage, options);
Vue.use(VCalendar);
Vue.use(VueApexCharts);
Vue.use(VueCroppie);
Vue.use(VueAxios, axios);
Vue.use(VueTippy, {
    defaultProps: { placement: "top" },
    popperOptions: {
        modifiers: {
            preventOverflow: {
                enabled: false,
            },
            hide: { enabled: false },
        },
    },
});

Vue.use(VueProgressBar, options_vue_progressBar);
Vue.use(VueSplide);
Vue.use(ProgressBar);
Vue.use(VueFormulate, {
    plugins: [es],
    locale: 'es',
    rules: {
        validRut: ({ value }) => ChileanRutify.validRut(value)
    },
    locales: {
        es: {
            validRut: () => `RUT inválido.`,
            required: () => `Campo requerido.`,
            email: () => `El correo no está bien formado.`,
            confirm: () => `Las contraseñas no coinciden.`,
            min: ({ args }) => `La contraseña debe tener al menos ${args[0]} caracteres.`,
            matches: () => `La contraseña debe tener al menos un número, una mayúscula y un caracter especial.`
        }
    },
    classes: {
        errors: 'validation-messages'
    }

});

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("tippy", TippyComponent);
Vue.component("apexchart", VueApexCharts);

Vue.prototype.$noResults = "No se han encontrado resultados para tu búsqueda";

const cache = new InMemoryCache({
    /* Person: {
      // In a user account system, the combination of a person's name AND email
      // address might uniquely identify them.
      keyFields: ["name", "email"],
    }, */
    User: {
        keyFields: [],
    },
});

Vue.config.productionTip = false;

import moment from "moment-timezone";

// main.js
import { apolloClientCC, apolloClient } from "./vue-apollo.js";

Vue.mixin({
    methods: {
        logOut() {
            apolloClient.clearStore();
            apolloClient.resetStore();   
            apolloClientCC.clearStore();
            apolloClientCC.resetStore();     
            // Cerrar las conexiones WebSocket 
            // (opcional si lo necesitas de forma manual)
            if (apolloClient.link.subscriptionClient) {
                apolloClient.link.subscriptionClient.close();
            }
            localStorage.clear();
            sessionStorage.clear();     
            this.$ls.remove("user");
            
            this.$router.push({ name: "Login" });          
        },
        redirectToLoneModule(modules) {
            if ((modules.length === 1) || (modules.length === 2 && modules.some(m => m.moduloGeneral.id_modulo === "1"))) {
                const onlyModule = modules.find(m => m.moduloGeneral.id_modulo != "1")
                if (onlyModule) {
                    this.$router.push({ path: onlyModule.moduloGeneral.ruta_admin });
                }
            }
        },


        dateFormatymd(dateString) {
            if (dateString != "Inicio" && dateString != "Término" && dateString != "Termino") {
                const date = moment.utc(dateString);
                if (date.isValid()) {
                    return date.format('DD/MM/YYYY');
                } else {
                    return 'Sin información';
                }
            } else {
                return dateString;
            }
        },
        dateFormatymdhours(dateString) {
            if (dateString != "Inicio" && dateString != "Término" && dateString != "Termino") {
                const date = moment.utc(dateString);
                if (date.isValid()) {
                    return date.format('DD/MM/YYYY HH:mm');
                } else {
                    return 'Sin información';
                }
            } else {
                return dateString;
            }
        },
        nowChileMoment() {
            return moment().tz("America/Santiago");
        },

        areEqual(...objects) {
            return objects.every(obj => JSON.stringify(obj) === JSON.stringify(objects[0]));
        },

        darkenHexColor(hex) {
            // Remove the hash at the start if it's there
            hex = hex.replace(/^#/, '');
          
            // Parse the hex into RGB components
            let r = parseInt(hex.substring(0, 2), 16);
            let g = parseInt(hex.substring(2, 4), 16);
            let b = parseInt(hex.substring(4, 6), 16);
          
            // Decrease each component according to the percent to darken
            r = parseInt(r * (1 - 0.1)).toString(16);
            g = parseInt(g * (1 - 0.1)).toString(16);
            b = parseInt(b * (1 - 0.1)).toString(16);
          
            // Ensure 2 digits by adding a zero if necessary
            r = r.length === 1 ? "0" + r : r;
            g = g.length === 1 ? "0" + g : g;
            b = b.length === 1 ? "0" + b : b;
          
            return "#" + r + g + b;
        },
          

        decodeJWT(token) {
            const parts = token.split('.');
            if (parts.length !== 3) {
                console.error('Token no tiene formato de JWT');
            }
            // const header = JSON.parse(atob(parts[0]));
            const payload = JSON.parse(atob(parts[1]));
            return payload;
        },

        toastGenericError() {
            this.$toast.open({
                message: "Error obteniendo información. Por favor recarga la página e intenta nuevamente.",
                type: "error",
                duration: 5000,
                position: "top-right",
            });
        },
        toastStatusChangeSuccess() {
            this.$toast.open({
                message: "Se ha cambiado el estado correctamente.",
                type: "success",
                duration: 5000,
                position: "top-right"
            });
        },
        toastStatusChangeError() {
            this.$toast.open({
                message: "Ocurrió un error al cambiar el estado. Por favor inténtalo nuevamente.",
                type: "error",
                duration: 5000,
                position: "top-right"
            });
        },
        toastServerError() {
            this.$toast.open({
                message: "Operación fallida. Por favor inténtalo nuevamente o contáctate con nosotros.",
                type: "error",
                duration: 5000,
                position: "top-right"
            });
        },
        capitalizeFirstLetter(texto) {
            return texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase()
        },
    },
});

// Directiva personalizada para detectar clicks afuera de elementos
Vue.directive("click-outside", {
    bind: function (element, binding, vnode) {
        //check that click was outside the el and his children
        element.clickOutsideEvent = function (event) {
            // and if it did, call method provided in attribute value
            if (!(element === event.target || element.contains(event.target))) {
                vnode.context[binding.expression](event);
                // binding.value(); run the arg
            }
        };
        document.body.addEventListener("click", element.clickOutsideEvent);
    },
    unbind: function (element) {
        document.body.removeEventListener("click", element.clickOutsideEvent);
    },
});

new Vue({
    router,
    store,
    cache,
    apolloProvider,
    watch: {
        $route: {
            handler: (to) => {
                document.title = to.meta.title || "LinkiWork";
            },
            immediate: true,
        },
    },
    render: (h) => h(App),
}).$mount("#app");